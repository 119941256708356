import Search from "src/components/search.vue";
import Channel from "src/components/channelcard.vue";
import Challenge from "src/components/challengecard.vue";
import Playlist from "src/components/playlistcard.vue";
import Story from "src/components/storycard.vue";
import Classroom from "src/components/classroomcard.vue";
import wavePath from "src/h/dist/waveWorker.min.js";
import decoderPath from "src/h/dist/decoderWorker.min.js?v=1";
import surfer from "src/components/surfer-mini.vue";
import userWeekActivity from "src/components/user/week-activity.vue";
import userLevel from "src/components/user/level.vue";
import api from "../../h/api";
import HelpVideo from "src/components/help-video.vue";
import h from "../../h/helpers";
import { i18n } from "../../boot/i18n";
import { v4 as uuidv4 } from "uuid";
import Notfound from "./notfound.vue";
import FavChannels from "src/components/favorite-channels.vue";

export default {
  data: () => ({
    searchbar: window.innerWidth > 1023,
    paramsURL: {},
    tab: null,
    blobRef: null,
    limit: 20,
    selectedWorkLangIDs: [],
    noMoreResultsToLoad: {
      // key=langID, value=count of fetched items, filled when there's no more items to load
      // used to hide "load more" button and languages without content
      channels: new Map([]),
      playlists: new Map([]),
      challenges: new Map([]),
      stories: new Map([]),
      classrooms: new Map([]),
    },
    classroomJoinByCodeDialog: false,
    classroomJoinByCode: null,
    classroomJoinByCodeLoading: false,
    query: null,
    searching: {
      refreshSearch: true,
    }, // refreshSearch true because we search for empty filter too (don't show 'nothing found' after F5 click)
    autoReload: false,
    foundItems: {
      // langid is the key
      channels: new Map([]),
      playlists: new Map([]),
      challenges: new Map([]),
      stories: new Map([]),
      classrooms: new Map([]),
    },
    categoriestree: {}, // tree by rules {fullname: {Object: {}, Children: {}}}
    categories: [], // current categories to show
    categoryquery: { item: {}, path: [] }, // item: item_from_tree, path:["x1","x2"]
    queryParams: {
      q: "",
      author: null,
      after: null,
      before: null,
      levelMin: null,
      levelMax: null,
      modeType: null,
      sourceLanguages: null,
      ignoreSystemRole: null,
    },
    recommendations: [],
    recommendationsGroup: null,
    recommendationLoaded: false,
    levels: [],
  }),
  components: {
    FavChannels,
    Notfound,
    Search,
    Channel,
    Challenge,
    Playlist,
    Story,
    Classroom,
    surfer,
    HelpVideo,
    userWeekActivity,
    userLevel,
  },
  created() {
    // console.trace("XXXXX: Created discovery"); TODO: created twice in the router 6cda6389
    this.wavWorkerRef = new Worker(wavePath);
    this.decoderWorkerRef = new Worker(decoderPath);
  },
  async mounted() {
    // avoid loading all langs if user langs are still not loaded
    await this.waitforUserAndLanguagesToLoad();
    this.clearResults();
    this.firstLoad();
    this.setupWorkers(
      this.wavWorkerRef,
      this.decoderWorkerRef,
      this,
      "blobRef",
    );
    this.parseLanguageID();
  },
  unmounted() {
    if (this.wavWorkerRef) {
      this.wavWorkerRef.terminate();
    }
    if (this.decoderWorkerRef) {
      this.decoderWorkerRef.terminate();
    }
  },
  methods: {
    pause(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async waitforUserAndLanguagesToLoad() {
      while (!Array.isArray(this.languages) || !Array.isArray(this.worklangs)) {
        await this.pause(50);
      }
      return true;
    },
    setupWorkers(wavWorker, decoderWorker, pointer, blobName) {
      wavWorker.onmessage = (e) => {
        if (e.data.message === "page") {
          const wavblob = new Blob([e.data.page], { type: "audio/wav" });
          pointer[blobName] = URL.createObjectURL(wavblob, {
            oneTimeOnly: true,
          });
        }
      };

      decoderWorker.onmessage = function (e) {
        if (e.data === null) {
          wavWorker.postMessage({ command: "done" });
        } else {
          wavWorker.postMessage(
            {
              command: "encode",
              buffers: e.data,
            },
            e.data.map((typedArray) => typedArray.buffer),
          );
        }
      };
    },
    loadAudio(type, audioSampleID, wavWorker, decoderWorker) {
      return api
        .Call({
          url: "/api/v1/asset/object/audiosample/" + type + "/" + audioSampleID,
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "audio/ogg",
          },
        })
        .then((response) => {
          const typedArray = new Uint8Array(response);
          wavWorker.postMessage({ command: "init", wavSampleRate: 48000 });
          decoderWorker.postMessage({ command: "init" });
          decoderWorker.postMessage(
            {
              command: "decode",
              pages: typedArray,
            },
            [typedArray.buffer],
          );
        });
    },
    startPreview(refID, AudioSampleRefID) {
      this.$refs[refID].previewLoading = true;
      this.$refs.refSurfer.stop();
      this.loadAudio(
        "ref",
        AudioSampleRefID,
        this.wavWorkerRef,
        this.decoderWorkerRef,
      ).finally(() => {
        this.$refs[refID].previewLoading = false;
      });
    },
    firstLoad() {
      this.parseParamsUrl();

      if (this.paramsURL["q"]) {
        this.setQuery(this.paramsURL["q"]);
      } else {
        this.setQuery("");
      }

      let tab = this.paramsURL["t"];
      if (tab) {
        tab = tab.toLowerCase();
      }
      if (
        [
          "lens",
          "channels",
          "challenges",
          "playlists",
          "stories",
          "classrooms",
        ].includes(tab)
      ) {
        this.tab = this.paramsURL["t"];
      } else {
        this.tab = "lens";
        this.setParamsUrl({ t: this.tab });
      }

      this.getCategoryLevel({ fullname: this.paramsURL["cat"] }).then((res) => {
        this.cat = res;
        this.setCategories(res);
        this.autoReload = true;
        this.reload(true);
      });
    },
    findCategory(fullName) {
      this.getCategoryLevel({ fullname: fullName }).then((res) => {
        this.setCategories(res);
        this.setParamsUrl({ cat: res });
        if (this.autoReload) {
          this.reload(true);
        }
      });
    },
    findCategoryByName(name) {
      // select from breadcrumbs
      if (!this.categoryquery.path || this.categoryquery.path.length === 0) {
        return;
      }
      // don't run if use selected value
      if (
        name === this.categoryquery.path[this.categoryquery.path.length - 1]
      ) {
        return;
      }
      let fullName = "";
      if (name) {
        for (var i = 0; i < this.categoryquery.path.length; i++) {
          fullName += this.categoryquery.path[i];
          if (name === this.categoryquery.path[i]) {
            break;
          }
          fullName += "/";
        }
      }

      this.findCategory(fullName);
    },
    // parse params in paramsURL and call search if need
    parseParamsUrl() {
      const query = this.$router.currentRoute.value.query;
      if (!query) {
        return;
      }
      this.paramsURL = { ...query };
    },
    // save params in URL (don't reload page and push in browser history!)
    setParamsUrl(params) {
      const query = { ...this.$route.query };
      for (const name in params) {
        this.paramsURL[name] = encodeURIComponent(
          decodeURIComponent(params[name]),
        );
      }
      for (const name in this.paramsURL) {
        query[name] =
          this.paramsURL[name]?.length > 0 ? this.paramsURL[name] : "";
      }
      this.$router.replace({ query });
    },
    parseLanguageID() {
      if (this.paramsURL.hasOwnProperty("languageID")) {
        this.selectedWorkLangIDs = decodeURIComponent(
          this.paramsURL.languageID,
        ).split(",");
      } else {
        this.setWorkLanguagesFilter();
      }
    },
    translateCategory(name) {
      const tName = "-raw-values-category-" + name;
      const name_ = this.$t(tName);
      if (name_ !== tName) {
        return name_.replace(
          /(&#(\d+);)/g,
          function (match, capture, charCode) {
            return String.fromCharCode(charCode);
          },
        );
      }
      return name;
    },
    getItemsByLangId(type, langId, batchToLoad = 3) {
      if (!this.foundItems.hasOwnProperty(type)) {
        console.error("error-7681dc90: wrong type: ", type);
        return;
      }
      if (langId === undefined) {
        console.error("error-77e4f373: langid is undefined");
        return;
      }

      let arr = this.foundItems[type].get(langId) || [];
      let sourceLanguageGroup =
        arr.length > 0 ? arr[arr.length - 1].SourceLanguageGroup : 0;
      let offset = arr.filter(
        (item) => item.SourceLanguageGroup === sourceLanguageGroup,
      ).length;

      this.searchItems({
        type: type,
        offset: offset,
        add: true,
        limit: batchToLoad,
        langid: langId,
        sourceLanguageGroup: sourceLanguageGroup,
      }).then((res) => {
        if (!Array.isArray(res) || res.length < batchToLoad)
          this.noMoreResultsToLoad[type].set(
            langId,
            offset + (res || []).length,
          );
      });
    },
    clearResults() {
      for (const type in this.foundItems) {
        this.foundItems[type].clear();
      }
      for (const type in this.noMoreResultsToLoad) {
        this.noMoreResultsToLoad[type].clear();
      }
    },
    reload(full) {
      const tab = this.tab.toLowerCase();
      // check if tab is valid discovery item type
      if (!this.foundItems.hasOwnProperty(tab)) {
        return;
      }
      if (full) this.clearResults();
      const theCurrentLangList =
        this.listWorkLangsOrReturnAllOfThemOrActuallyCheckForZerosToo;
      theCurrentLangList.forEach((lang) => {
        this.getItemsByLangId(tab, lang.languageid);
      });
    },
    setLanguageFilter(id) {
      const selectedIndex = this.selectedWorkLangIDs?.indexOf(id);
      if (selectedIndex === -1 || selectedIndex === undefined) {
        this.selectedWorkLangIDs.push(id);
      } else {
        this.selectedWorkLangIDs.splice(selectedIndex, 1);
      }
      this.setParamsUrl({ languageID: this.selectedWorkLangIDs });
      if (this.autoReload) {
        this.reload(true);
      }
    },
    clearLanguageFilter() {
      this.selectedWorkLangIDs = [];
      this.setParamsUrl({ languageID: this.selectedWorkLangIDs });
      if (this.autoReload) {
        this.reload(true);
      }
    },
    setWorkLanguagesFilter() {
      this.selectedWorkLangIDs = this.worklangs.map((lang) => lang.languageid);
      this.setParamsUrl({ languageID: this.selectedWorkLangIDs });
      if (this.autoReload) {
        this.reload(true);
      }
    },
    // search may return duplicates, but we can't store deduplicated array
    // because we need to track search offset && last element's SourceLanguageGroup
    // for each language and item type
    arrayDeduplicate(arr) {
      if (!Array.isArray(arr)) return [];
      return arr.reduce((uniqueArray, currentObj) => {
        const isDuplicate = uniqueArray.some((obj) => obj.ID === currentObj.ID);
        if (!isDuplicate) {
          uniqueArray.push(currentObj);
        }
        return uniqueArray;
      }, []);
    },
    afterShowDialogEnterClassroomCode() {
      this.$refs["inputCodeJoinClassroom"]?.focus();
    },
    enterClassroomByCode() {
      this.classroomJoinByCodeLoading = true;
      api
        .Call({
          url: "/api/v1/classroom/join_by_code/" + this.classroomJoinByCode,
          method: "post",
          show_error: true,
        })
        .then(
          (result) => {
            this.classroomJoinByCodeLoading = false;
            this.classroomJoinByCodeDialog = false;
            // note: ref-719d1def:
            if (result.NewJoin === true && result.FocusOrganizationID) {
              this.$store.commit(
                "setUserFocusOrganizationID",
                result.FocusOrganizationID,
              );
            }
            this.$router.push("/classroom/" + result.ClassroomID);
          },
          (e) => {
            this.classroomJoinByCodeLoading = false;
          },
        );
    },
    setQuery(newQuery) {
      if ((newQuery || "") !== "" && this.tab === "lens") {
        const autoReload_ = this.autoReload;
        this.autoReload = false;
        this.setTab("challenges");
        this.autoReload = autoReload_;
      }
      this.query = decodeURIComponent(newQuery) || "";
      const resultParams = {
        q: "",
        author: null,
        after: null,
        before: null,
        levelMin: null,
        levelMax: null,
        modeType: null,
        sourceLanguages: null,
        ignoreSystemRole: null,
      };
      if (this.query?.includes(":")) {
        this.query.split(/(\s+)/).forEach((word) => {
          const colonIndex = word.indexOf(":");
          if (colonIndex > -1) {
            const paramName = word.substring(0, colonIndex);
            const paramValue = word.substring(colonIndex + 1);
            if (paramName in resultParams) {
              resultParams[paramName] = paramValue;
            } else {
              resultParams.q += word;
            }
          } else {
            resultParams.q += word;
          }
        });
      } else {
        resultParams.q = this.query;
      }
      this.queryParams = resultParams;
      this.setParamsUrl({ q: newQuery });
      if (this.autoReload) {
        this.reload(true);
      }
    },
    setFoundItems(payload) {
      if (!this.foundItems.hasOwnProperty(payload.type)) {
        console.error("setFoundItems wrong type: ", payload.type);
        return;
      }
      if (!payload.rows) {
        payload.rows = [];
      }
      if (!this.foundItems[payload.type].has(payload.langid)) {
        this.foundItems[payload.type].set(payload.langid, []);
      }
      if (payload.add === true) {
        this.foundItems[payload.type].get(payload.langid).push(...payload.rows);
      } else {
        this.foundItems[payload.type].set(payload.langid, [...payload.rows]);
      }
    },
    setTab(newTab) {
      this.tab = newTab;
      this.setParamsUrl({ t: newTab });
      if (this.autoReload) {
        this.reload(false);
      }
    },
    setCategoryLevel(newLevel) {
      let fullName = "";
      if (newLevel.Object && newLevel.Object.FullName) {
        fullName = newLevel.Object.FullName;
      }
      this.categoriestree[fullName] = newLevel;
    },
    setCategories(categoryLevel) {
      if (this.categoriestree[categoryLevel]) {
        const category = this.categoriestree[categoryLevel];
        this.categories.splice(0, this.categories.length, ...category.Children);

        if (category.Object.FullName !== "") {
          this.categoryquery.item = category.Object;
          this.categoryquery.path.splice(
            0,
            this.categoryquery.path.length,
            ...categoryLevel.split("/"),
          );
        } else {
          this.categoryquery.item = {};
          this.categoryquery.path = [];
        }
      } else {
        this.categories = [];
        this.categoryquery.item = {};
        this.categoryquery.path = [];
      }
    },
    getCategoryLevel(category) {
      return new Promise((resolve, reject) => {
        const fullName = category.fullname || "";

        if (!this.categoriestree[fullName]) {
          let url = "/api/v1/category/level";
          let params = {};

          if (category.fullname) {
            params.fullname = category.fullname;
          }

          api
            .Call({
              url: url,
              method: "get",
              params: params,
            })
            .then((response) => {
              if (!response.Children) {
                response.Children = [];
              }
              if (!response.Object) {
                response.Object = { FullName: "" };
              }

              this.setCategoryLevel(response);
              resolve(response.Object.FullName || "");
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        } else {
          this.setCategoryLevel(this.categoriestree[fullName]);
          resolve(fullName);
        }
      });
    },
    searchItems(payload) {
      return new Promise((resolve, reject) => {
        let url = "/api/v1/search/";
        switch (payload.type) {
          case "challenges":
            url += "feed?recent=0&rated=0&popular=0&random=1&type=challenge";
            break;
          case "channels":
            url += "feed?recent=0&rated=0&popular=0&random=1&type=channel";
            break;
          case "playlists":
            url += "feed?recent=0&rated=0&popular=0&random=1&type=playlist";
            break;
          case "stories":
            url +=
              "feed?withAccess=true&recent=0&rated=0&popular=0&random=1&type=story" +
              `&withCurrentPassing=false&userLevel=${h.levelToCEFR(
                this.userLevel,
              )}`;
            break;
          case "classrooms":
            url += "classroom?";
            break;
          default:
            reject(`error-6ca2a5b9 wrong item type: ${payload.type}`);
        }
        url +=
          `&offset=${payload.offset ?? 0}&limit=${payload.limit ?? 10}` +
          `&sourceLanguageGroup=${payload.sourceLanguageGroup ?? 0}`;

        for (const [key, value] of Object.entries(this.queryParams)) {
          if (value && key !== "sourceLanguages") {
            url += `&${key}=${encodeURI(value)}`;
          }
        }

        // source languages
        if (this.languages) {
          let sourceLanguageID = this.languages.find(
            (val) => val.locale === i18n.global.locale,
          )?.ID;
          if (sourceLanguageID) {
            url += `&sourceLocaleLanguageID=${encodeURI(sourceLanguageID)}`;
          }
        }
        if (this.user?.currentlang) {
          let sourcelanguageids = [];
          this.user?.currentlang.forEach((ch) => {
            sourcelanguageids.push(ch.languageid);
          });
          url += `&sourceLanguageIDs=${encodeURI(
            sourcelanguageids.toString(),
          )}`;
        }

        if (this.queryParams["sourceLanguages"] === "true") {
          url += `&sourceLanguages=true`;
        }

        if (this.queryParams["ignoreSystemRole"] === "true") {
          url += `&ignoreSystemRole=true`;
        }

        if (
          ["challenges", "channels", "playlists", "stories"].includes(
            payload.type,
          ) &&
          this.categoryquery.item &&
          this.categoryquery.item.FullName
        ) {
          url += "&cat=" + encodeURI(this.categoryquery.item.FullName);
        }

        if (payload.langid) {
          url += `&languageID=${payload.langid}`;
        }

        // store UUIDs in this.searching to detect if search is still going by at least one language
        const searchUUID = uuidv4();
        delete this.searching.refreshSearch;
        this.searching[searchUUID] = true;

        let lnk = this;
        api
          .Call({
            url: url,
            method: "get",
          })
          .then(
            (response) => {
              let rows = [];
              if (
                ["challenges", "channels", "playlists", "stories"].includes(
                  payload.type,
                )
              ) {
                rows =
                  response
                    ?.filter((row) => !!row.Object)
                    .map((row) => ({
                      ...row.Object,
                      SourceLanguageGroup: row.SourceLanguageGroup,
                    })) || [];
              } else {
                rows = response || [];
              }

              lnk.setFoundItems({
                type: payload.type,
                rows: rows,
                add: payload.add,
                langid: payload.langid,
              });
              resolve(rows);
            },
            (e) => {
              console.error(e);
              reject(e);
            },
          )
          .finally(() => {
            delete lnk.searching[searchUUID];
          });
      });
    },
    itemsWorklangsWithoutEmpty(itemType) {
      return this.listWorkLangsOrReturnAllOfThemOrActuallyCheckForZerosToo.filter(
        (lang) =>
          !(this.noMoreResultsToLoad[itemType].get(lang.languageid) === 0),
      );
    },
    getUserRecommendations(next) {
      this.recommendationLoaded = false;
      api
        .Call({
          url:
            "/api/v1/recommendation/user?Limit=3" +
            "&GroupID=" +
            (next === true && this.recommendationsGroup
              ? this.recommendationsGroup
              : ""),
          show_error: false,
        })
        .then(
          (result) => {
            if (result.List == null || result.List == undefined) {
              result.List = [];
            }
            if (next) {
              this.recommendations.splice(
                this.recommendations.length,
                0,
                ...result.List,
              );
            } else {
              this.recommendations = result.List;
            }
            this.recommendationsGroup = result.GroupID;
            this.recommendationLoaded = true;
          },
          (e) => {
            console.error("Get recommendation user ", e);
          },
        );
    },
    getUserLevels(next) {
      api
        .Call({
          url: "/api/v1/statistics/user/user_compare_level",
          show_error: false,
        })
        .then(
          (result) => {
            if (result?.List == null || result?.List == undefined) {
              this.levels.splice(0, this.levels.length, ...[]);
            } else {
              this.levels.splice(0, this.levels.length, ...result.List);
            }
          },
          (e) => {
            console.error("Get user level", e);
          },
        );
    },
    storyDeleted(ID, languageIDs) {
      if (!languageIDs) {
        return;
      }
      for (let i = 0; i < languageIDs.length; i++) {
        if (this.foundItems.stories.has(languageIDs[i])) {
          this.foundItems.stories.get(languageIDs[i]).splice(
            this.foundItems.stories
              .get(languageIDs[i])
              .findIndex((element) => element.ID === ID),
            1,
          );
        }
      }
      // I guess we don't need check/recalc noMoreResultsToLoad value (saved last count search elements)?
    },
  },
  watch: {
    searching: {
      handler(newVal, oldVal) {
        if (Object.keys(newVal).length > 0) {
          this.$refs.progressAjax.start();
        } else {
          this.$refs.progressAjax.stop();
        }
      },
    },
    tab: {
      handler(newVal, oldVal) {
        if (newVal == "lens") {
          this.getUserRecommendations();
          this.getUserLevels();
        }
      },
      immediate: true,
    },
  },
  computed: {
    h() {
      return h;
    },
    left() {
      return this.$store.getters.left;
    },
    isSearching() {
      return Object.keys(this.searching).length > 0;
    },
    worklangs() {
      return this.$store.getters.user.worklang;
    },
    languages() {
      return this.$store.getters.languages;
    },
    listWorkLangsOrReturnAllOfThemOrActuallyCheckForZerosToo() {
      const ret = this.worklangs.filter((lang) =>
        this.selectedWorkLangIDs.includes(lang.languageid),
      );

      // If no languages added, let's add ALL the languages we have.
      if (ret.length < 1) {
        return this.languages.map((lang) => ({
          languageid: lang.ID,
          languagename: lang.name,
        }));
      }

      return ret;
    },
  },
};
