<template>
  <!-- Banner -->
  <div
    class="column no-wrap"
    :class="$q.screen.gt.sm ? 'full-height' : 'full-width'"
    :style="{
      background:
        ($q.dark.isActive
          ? 'linear-gradient(rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)), '
          : 'linear-gradient(rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.42)), ') +
        `no-repeat`,
      marginRight: $q.screen.gt.sm ? '-0.5rem' : '',
      paddingRight: $q.screen.gt.sm ? '0.5rem' : '',
      width: $q.screen.gt.sm ? 'calc(100% + 0.5rem)' : '',
      height: $q.screen.gt.sm ? '' : '100%',
    }"
    style="
      background-size: cover;
      background-color: var(--q-5);
      position: relative;
    "
  >
    <!-- q-gutter-sm q-ml-md q-mr-sm q-mt-md full-height  -->
    <q-form
      class="q-gutter-y-sm q-ml-md q-mt-md"
      :style="{
        marginRight: $q.screen.gt.sm ? '0.5rem' : '1rem',
      }"
    >
      <div class="col column" v-show="isloaded">
        <div class="row full-width no-wrap justify-center items-center">
          <q-input
            :ref="'filter_' + id"
            v-model="filterTree"
            borderless
            type="text"
            :placeholder="$t('-raw-assets-search-group-v2')"
            debounce="500"
            q-input
            style="
              border-radius: 0.5rem;
              border: 2px solid var(--q-2);
              box-shadow: 0 0 2rem var(--q-0);
              transition-duration: 0.2s;
              pointer-events: auto;
            "
            class="bg-0 q-px-md full-width"
            :disable="!(tree && tree.length > 0)"
          >
            <template #prepend>
              <q-icon name="search" />
            </template>
            <template #append>
              <q-icon
                v-if="filterTree !== ''"
                name="clear"
                class="cursor-pointer"
                @click="resetFilter"
              ></q-icon>
            </template>
          </q-input>
          <q-btn
            no-wrap
            class="bg-0 q-pa-sm q-ml-sm"
            flat
            style="
              border-radius: 1rem;
              text-align: right;
              width: 3rem;
              height: 3rem;
            "
            @click="addNewGroup"
            :class="addingNode.op ? 'bg-p text-0' : 'text-p'"
            v-if="canupdate"
          >
            <q-icon name="add" />
            <q-tooltip
              anchor="top right"
              self="bottom right"
              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
              transition-show="jump-right"
              transition-hide="jump-left"
            >
              {{ $t("-raw-assets-add-new-group") }}
            </q-tooltip>
          </q-btn>
        </div>
        <div
          class="row full-width no-wrap justify-center items-center q-gutter-sm q-mt-md"
          v-show="addingNode.op"
        >
          <q-input
            type="text"
            :label="$t('-raw-assets-new-group')"
            borderless
            clearable
            v-model="addingNode.Name"
            class="glassy-8 bg-transparent col q-ml-md q-px-md edit-style"
            input-class="ubuntu bg-transparent"
            input-style="color: var(--q-a);"
            :ref="'newgroup_' + id"
            :readonly="isAddingNode"
          />
          <q-btn
            class="bg-0 q-pa-sm edit-style"
            flat
            style="
              border-radius: 5rem;
              text-align: right;
              border-radius: 1rem;
              text-align: right;
              width: 3rem;
              height: 3rem;
            "
            size="sm"
            :disable="!addingNode.Name"
            @click="addNewGroupData"
            :loading="isAddingNode"
          >
            <q-icon name="done" color="p" />
          </q-btn>
          <q-btn
            no-wrap
            class="bg-0 q-pa-sm edit-style"
            flat
            style="
              border-radius: 1rem;
              text-align: right;
              border-radius: 1rem;
              text-align: right;
              width: 3rem;
              height: 3rem;
            "
            size="sm"
            @click="addNewGroupCancel"
          >
            <q-icon name="cancel" color="n" />
          </q-btn>
        </div>

        <q-separator class="q-mt-md" v-show="addingNode.op" />

        <q-scroll-area
          class="q-mt-xs q-pr-md"
          visible
          :delay="1200"
          :style="{
            height:
              `calc(${window.innerHeight}px - ` +
              (addingNode.op ? `23rem` : `17rem`) +
              `)`,
          }"
          v-show="tree && tree.length > 0 && !filterResultEmpty"
        >
          <q-tree
            :nodes="tree"
            label-key="Name"
            node-key="ID"
            children-key="Children"
            :ref="'treeview_' + id"
            :filter="filterTree"
            :filter-method="treeFilterMethod"
            :selected="selectionTree"
            @input="$emit('updated:selected', $event.target.value)"
            @update:selected="getSelected"
            default-expand-all
            class="q-pt-sm"
          >
            <template #default-header="prop">
              <div
                class="row full-width items-center ubuntu q-px-md glassy-8 text-8 items-center q-py-sm"
                style="border-radius: 1rem"
                @drop="dragdrop(prop.node.ID, $event)"
                @dragover.prevent
                @dragenter.prevent
              >
                <div
                  v-if="
                    !editingSubnode?.ID || prop.node.ID !== editingSubnode.ID
                  "
                  :class="
                    selectedTreeNode.ID == prop.node.ID
                      ? 'text-v text-weight-bold'
                      : ''
                  "
                >
                  {{ prop.node.Name }}
                </div>
                <div
                  class="text-p text-bold text-italic"
                  v-if="editingSubnode?.ID && prop.node.ID == editingSubnode.ID"
                >
                  Edit subgroup
                </div>
              </div>
            </template>
            <template #default-body="prop">
              <div
                class="full-width"
                v-if="canupdate && prop.node.ID === selectedTreeNode.ID"
                :class="
                  prop.node.ParentID == null &&
                  (!prop.node.Children || prop.node.Children.length == 0)
                    ? 'q-pl-md'
                    : ''
                "
              >
                <div
                  class="row full-width no-wrap justify-center items-center q-gutter-sm"
                  v-if="addingSubnode.ParentID === prop.node.ID"
                >
                  <q-input
                    type="text"
                    :label="$t('-raw-assets-new-subgroup')"
                    borderless
                    clearable
                    v-model="addingSubnode.Name"
                    style="border-radius: 1rem"
                    class="glassy-8 bg-transparent col q-px-md edit-style"
                    input-class="ubuntu bg-transparent"
                    input-style="color: var(--q-a);"
                    :ref="'newsubgroup_' + id"
                    :readonly="isAddingSubNode"
                  />
                  <q-btn
                    class="bg-0 q-pa-sm edit-style"
                    flat
                    style="
                      border-radius: 5rem;
                      text-align: right;
                      border-radius: 1rem;
                      text-align: right;
                      width: 3rem;
                      height: 3rem;
                    "
                    size="sm"
                    :disable="!addingSubnode.Name"
                    @click="addNewSubgroupData"
                    :loading="isAddingSubNode"
                  >
                    <q-icon name="done" color="p" />
                  </q-btn>
                  <q-btn
                    no-wrap
                    class="bg-0 q-pa-sm edit-style"
                    flat
                    style="
                      border-radius: 1rem;
                      text-align: right;
                      border-radius: 1rem;
                      text-align: right;
                      width: 3rem;
                      height: 3rem;
                    "
                    size="sm"
                    @click="addNewSubgroupCancel"
                  >
                    <q-icon name="cancel" color="n" />
                  </q-btn>
                </div>
                <div
                  class="row full-width no-wrap justify-center items-center q-gutter-sm"
                  v-else-if="editingSubnode.ID === prop.node.ID"
                >
                  <q-input
                    type="text"
                    label="Name"
                    borderless
                    clearable
                    v-model="editingSubnode.Name"
                    style="border-radius: 1rem"
                    class="glassy-8 bg-transparent col q-px-md edit-style"
                    input-class="ubuntu bg-transparent"
                    input-style="color: var(--q-a);"
                    :ref="'editsubgroup_' + id"
                    :readonly="isEditingSubNode"
                  />
                  <q-btn
                    class="bg-0 q-pa-sm edit-style"
                    flat
                    style="
                      border-radius: 5rem;
                      text-align: right;
                      border-radius: 1rem;
                      text-align: right;
                      width: 3rem;
                      height: 3rem;
                    "
                    size="sm"
                    :disable="!editingSubnode.Name"
                    @click="editSubgroupData"
                    :loading="isEditingSubNode"
                  >
                    <q-icon name="done" color="p" />
                  </q-btn>
                  <q-btn
                    no-wrap
                    class="bg-0 q-pa-sm edit-style"
                    flat
                    style="
                      border-radius: 1rem;
                      text-align: right;
                      border-radius: 1rem;
                      text-align: right;
                      width: 3rem;
                      height: 3rem;
                    "
                    size="sm"
                    @click="editSubgroupCancel"
                  >
                    <q-icon name="cancel" color="n" />
                  </q-btn>
                </div>
                <div v-else class="q-gutter-sm">
                  <q-btn
                    no-wrap
                    class="bg-0 q-pa-sm"
                    flat
                    style="border-radius: 1rem; text-align: right"
                    size="sm"
                    @click="editSubgroup(prop.node)"
                  >
                    <q-icon name="edit" color="v" />
                  </q-btn>
                  <q-btn
                    no-wrap
                    class="bg-0 q-pa-sm"
                    flat
                    style="border-radius: 1rem; text-align: right"
                    size="sm"
                    v-if="selectedOrder?.next"
                    @click="changeOrder(prop.node, 1)"
                  >
                    <q-icon name="south" />
                  </q-btn>
                  <q-btn
                    no-wrap
                    class="bg-0 q-pa-sm"
                    flat
                    style="border-radius: 1rem; text-align: right"
                    size="sm"
                    v-if="selectedOrder?.prev"
                    @click="changeOrder(prop.node, -1)"
                  >
                    <q-icon name="north" />
                  </q-btn>
                  <q-btn
                    no-wrap
                    class="bg-0 q-pa-sm"
                    flat
                    style="border-radius: 1rem; text-align: right"
                    size="sm"
                    @click="deleteSubgroup(prop.node)"
                  >
                    <q-icon name="delete" color="n" />
                  </q-btn>
                  <q-btn
                    no-wrap
                    class="bg-0 q-pa-sm"
                    flat
                    style="border-radius: 1rem; text-align: right"
                    size="sm"
                    @click="addNewSubgroup(prop.node)"
                  >
                    <q-icon name="add" color="p" />
                  </q-btn>
                </div>
              </div>
            </template>
          </q-tree>
        </q-scroll-area>

        <div
          class="q-pt-md row full-width justify-center"
          v-show="filterResultEmpty"
        >
          Empty search
        </div>
        <div
          class="q-pt-md row full-width justify-center"
          v-show="!(tree && tree.length > 0)"
        >
          {{ $t("-raw-assets-no-group") }}
        </div>
      </div>
      <div
        class="column full-width row items-center q-pa-md"
        v-show="!isloaded"
      >
        <q-spinner-ball color="primary" size="lg" />
      </div>
    </q-form>
  </div>
</template>

<script>
// import { Notify } from "quasar";
import h from "src/h/helpers.js";
import api from "src/h/api.js";
// import { i18n } from "src/boot/i18n.js";

export default {
  emits: ["save", "read", "groupchanged", "treechanged"],
  components: {},
  props: {
    plibrary: {
      type: Object,
      default: function () {
        return {};
      },
    },
    pid: {
      type: String,
      default: "",
    },
    pcanupdate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data: () => ({
    id: null,
    library: {},
    canupdate: false,
    tree: [],
    filterTree: "",
    selectedTreeNode: {},
    selectedOrder: {},
    selectionTree: "",
    filteredTreeNodes: [],
    filterResultEmpty: false,
    isloaded: false,
    addingNode: {
      op: false, // null if not adding
      Name: null,
    },
    isAddingNode: false, // need show loading (for button)
    addingSubnode: {
      Name: null,
      ParentID: null,
    },
    isAddingSubNode: false,
    editingSubnode: {},
    isEditingSubNode: false,
    dragItem: {},
  }),
  watch: {
    filterTree: {
      handler(newVal, oldVal) {
        this.filterNodeExists();
      },
    },
  },
  beforeMount() {
    this.library = this.plibrary;
    this.canupdate = this.pcanupdate;
    this.id = this.pid;
  },
  mounted() {
    // // test
    // this.tree = [
    //   {
    //     Name: "Satisfied customers",
    //     ID: 1,
    //     Children: [
    //       {
    //         Name: "Good food",
    //         ID: 2,
    //         Children: [
    //           { Name: "Quality ingredients", ID: 3 },
    //           { Name: "Good recipe", ID: 4 },
    //         ],
    //       },
    //       {
    //         Name: "Good service (disabled node) (*)",
    //         ID: 5,
    //         Children: [
    //           { Name: "Prompt attention", ID: 6 },
    //           { Name: "Professional waiter", ID: 7 },
    //         ],
    //       },
    //       {
    //         Name: "Pleasant surroundings",
    //         ID: 8,
    //         Children: [
    //           { Name: "Happy atmosphere (*)", ID: 9 },
    //           { Name: "Good table presentation", ID: 10 },
    //           { Name: "Pleasing decor (*)", ID: 11 },
    //         ],
    //       },
    //     ],
    //   },
    // ];
    this.read({}, "select_category_library");
  },
  beforeUnmount() {},
  methods: {
    save(data, method) {
      this.$emit("save", data, method);
    },
    read(data, method) {
      this.$emit("read", data, method);
    },

    treeFilterMethod(node, filter) {
      const filt = filter.toLowerCase();
      return node.Name && node.Name.toLowerCase().indexOf(filt) > -1;
    },
    resetFilter() {
      this.filterTree = "";
      // this.$refs["filter_" + this.id].focus();
      this.focus("filter_");
    },
    getSelected(val) {
      // select on tree click
      if (val) {
        if (this.selectedTreeNode?.ID === val) {
          // if click in selected node, clear selection
          this.selectedTreeNode = {};
        } else {
          this.selectedTreeNode =
            this.$refs["treeview_" + this.id].getNodeByKey(val);
          // console.log(this.$refs.['treeview_'+lnk.id].meta);

          if (this.addingSubnode.ParentID == val) {
            this.focus("newsubgroup_");
            this.isAddingSubNode = false;
          }
          this.$refs["treeview_" + this.id].setExpanded(val, true); // open nodes
        }
        this.emitGroupChanged();
      }
    },
    emitGroupChanged() {
      this.getSelectedOrder();
      this.getSelectedNamePath();
      this.$emit("groupchanged", this.selectedTreeNode);
    },
    emitTreeChanged() {
      let lnk = this;
      setTimeout(() => {
        lnk.$emit("treechanged", lnk.tree);
      }, 50);
    },
    // user filterMethod code, need this method check exists any node by filter
    filterNodeExists() {
      var lnk = this;
      var fnd = function (arr) {
        for (var i = 0; i < arr.length; i++) {
          if (lnk.treeFilterMethod({ Name: arr[i].Name }, lnk.filterTree)) {
            return true;
          }
          if (arr[i].Children && arr[i].Children.length > 0) {
            if (fnd(arr[i].Children)) {
              return true;
            }
          }
        }
        return false;
      };
      if (fnd(this.tree)) {
        this.filterResultEmpty = false;
      } else {
        this.filterResultEmpty = true;
      }
    },
    // getFilteredNodes() {
    //   let tree = this.$refs["treeview_" + this.id].$refs;
    //   this.filteredTreeNodes = Object.keys(tree)
    //     .filter((node) => tree[node] !== undefined)
    //     .map((nodename) => nodename.replace("blurTarget_", ""));
    // },
    addNewGroup() {
      // show div with input
      this.addingNode.Name = null;
      this.addingNode.op = true;
      this.focus("newgroup_");
      this.isAddingNode = false;
    },
    addNewGroupData() {
      // add group logic
      this.save(
        {
          Name: this.addingNode.Name,
        },
        "insert_category_library",
      );
      this.isAddingNode = true;
    },
    addNewGroupCancel() {
      this.addingNode.op = false;
      this.addingNode.Name = null;
    },
    addNewSubgroup(node) {
      // show div
      this.addingSubnode.ParentID = node.ID;
      this.addingSubnode.Name = null;
      this.isAddingSubNode = false;
      this.focus("newsubgroup_");
    },
    addNewSubgroupData() {
      // add group logic
      this.save(
        {
          Name: this.addingSubnode.Name,
          ParentID: this.addingSubnode.ParentID,
        },
        "insert_category_library",
      );
      this.isAddingSubNode = true;
    },
    addNewSubgroupCancel() {
      this.addingSubnode.ParentID = null;
      this.addingSubnode.Name = null;
    },
    editSubgroup(node) {
      h.CopyObject(node, this.editingSubnode);
      this.isEditingSubNode = false;
      this.focus("editsubgroup_");
    },
    editSubgroupData() {
      // add group logic
      this.save(this.editingSubnode, "update_category_library");
      this.isEditingSubNode = true;
    },
    editSubgroupCancel() {
      this.editingSubnode = {};
    },
    deleteSubgroup(node) {
      let pnt = this;
      api.CallConfirmation(
        {
          method: "delete",
          buttonOk: this.$t("-raw-tooltip-delete"),
          message: this.$t("-raw-assets-delete-group-confirm"),
        },
        () => {
          return new Promise((resolve, reject) => {
            pnt.save(node, "delete_category_library");
            resolve();
          });
        },
      );
    },
    focus(op) {
      let obj = this.$refs[op + this.id];
      if (obj) {
        setTimeout(() => {
          obj.focus();
        }, 50);
      }
    },
    getSelectedNamePath() {
      if (this.selectedTreeNode.FullName) {
        //empty if nothing selected
        let name = [];
        let res = this.selectedTreeNode.FullName.split("/");
        let lnk = this;
        res.forEach(function (sbitem, inx) {
          let ext = lnk.$refs["treeview_" + lnk.id].getNodeByKey(sbitem);
          if (ext) {
            name.push({
              ID: ext.ID,
              Name: ext.Name,
            });
          }
        });
        this.selectedTreeNode.FullPath = name;
      }
    },
    getSelectedOrder() {
      let node = this.selectedTreeNode;
      let res = {
        prev: true,
        next: true,
      };

      if (node.ID) {
        let list = [];
        if (node?.ParentID) {
          list = this.$refs["treeview_" + this.id].getNodeByKey(
            node.ParentID,
          ).Children;
        } else {
          list = this.tree;
        }

        for (let i = 0; i < list.length; i++) {
          if (list[i].ID === node.ID) {
            if (i == 0) {
              res.prev = false;
            }
            if (i + 1 == list.length) {
              res.next = false;
            }
            break;
          }
        }
      }
      this.selectedOrder = res;
    },
    changeOrder(node, num) {
      this.save(
        {
          ID: node.ID,
          Num: num,
        },
        "update_category_library_order",
      );
    },
    sync(data, objname, isncurrentopt) {
      switch (objname) {
        case "error":
          switch (data.Operation) {
            case "insert_category_library":
              this.isAddingSubNode = false;
              this.isAddingNode = false;
              break;
            case "update_category_library":
              this.isEditingSubNode = false;
              break;
          }
          break;
        case "select_category_library":
          if (!data) {
            data = [];
          }
          this.tree.splice(0, this.tree.length, ...data);
          let lnk = this;
          setTimeout(() => {
            lnk.$refs["treeview_" + lnk.id].expandAll();
          }, 50);
          this.isloaded = true;
          this.emitTreeChanged();
          break;
        case "insert_category_library":
          if (data.ParentID === null) {
            this.tree.splice(0, 0, data);
            for (let i = 1; i < this.tree.length; i++) {
              this.tree[i].OrderNum += 1;
            }
            this.isAddingNode = false;
            this.focus("newgroup_");
            // this.addNewGroupCancel();
          } else {
            let node = this.$refs["treeview_" + this.id].getNodeByKey(
              data.ParentID,
            );
            if (node) {
              if (node.Children == null || node.Children == undefined) {
                node.Children = [data];
              } else {
                node.Children.splice(0, 0, data);
                for (let i = 1; i < node.Children.length; i++) {
                  node.Children[i].OrderNum += 1;
                }
              }
              this.$refs["treeview_" + this.id].setExpanded(
                data.ParentID,
                true,
              );
              // if we add child rows, tree need some time to draw it
              this.focus("newsubgroup_");
            }
            this.isAddingSubNode = false;
            // this.addNewSubgroupCancel();
          }
          this.emitTreeChanged();
          break;
        case "update_category_library":
          let nodeUp = this.$refs["treeview_" + this.id].getNodeByKey(data.ID);
          if (nodeUp) {
            h.CopyObject(data, nodeUp);
            this.editingSubnode = {};
            this.isEditingSubNode = false;
          }
          this.emitGroupChanged();
          this.emitTreeChanged();
          break;
        case "update_category_library_order":
          // we can't use data.Num1 or data.Num2 because it's can be different real array number (actual if have deleted rows)
          // so data.Num1, data.Num2 can say about order
          let nodeParent = this.tree;
          let fnd = function (arr, ID) {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].ID == ID) {
                return { Num: i, Node: arr[i] };
              }
            }
            return {};
          };

          if (data.ParentID !== null) {
            nodeParent = this.$refs["treeview_" + this.id].getNodeByKey(
              data.ParentID,
            ).Children;
          }

          let obj1 = fnd(nodeParent, data.ID1);
          obj1.Node.OrderNum = data.Num1;
          let obj2 = fnd(nodeParent, data.ID2);
          obj2.Node.OrderNum = data.Num2;
          let selected;
          if (obj1.Node.ID === this.selectedTreeNode.ID) {
            selected = obj1.Node;
          } else if (obj2.Node.ID === this.selectedTreeNode.ID) {
            selected = obj2.Node;
          } else {
            // order changed maybe from other user (don't need change selected)
            selected = this.selectedTreeNode;
          }
          nodeParent.splice(obj1.Num, 1, obj2.Node);
          nodeParent.splice(obj2.Num, 1, obj1.Node);

          this.selectedTreeNode = selected;
          this.emitGroupChanged();
          this.emitTreeChanged();
          break;
        case "delete_category_library":
          let nodeDel = this.$refs["treeview_" + this.id].getNodeByKey(data.ID);
          if (nodeDel) {
            let fnd = function (arr, ID) {
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].ID == ID) {
                  return i;
                }
              }
              return null;
            };
            if (nodeDel.ParentID !== null) {
              let nodeParent = this.$refs["treeview_" + this.id].getNodeByKey(
                nodeDel.ParentID,
              );
              let num = fnd(nodeParent.Children, data.ID);
              nodeParent.Children.splice(num, 1);
            } else {
              let num = fnd(this.tree, data.ID);
              this.tree.splice(num, 1);
            }
            this.selectedTreeNode = {};
            this.emitGroupChanged();
          }
          this.emitTreeChanged();
          break;
      }
    },
    dragdrop(GroupID, event) {
      // pressing Shft on drop will move to the group, otherwise it'll add group to the item's existing ones
      this.save(
        {
          FileID: this.dragItem.FileID,
          FileObjectID: this.dragItem.FileObjectID,
          Groups: event.shiftKey
            ? [GroupID]
            : [...this.dragItem.Groups, GroupID],
        },
        "file_group_change",
      );
    },
    startdrag(item) {
      this.dragItem = item;
    },
  },
};
</script>

<style scoped>
.edit-style {
  border-radius: 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--q-p);
}
</style>
