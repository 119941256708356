<template>
  <div
    class="column col fit justify-end"
    style="border-radius: 2rem"
    :style="`height: calc(${window.innerHeight}px - ${
      isFit === true ? '7' : '11'
    }rem !important`"
  >
    <div class="col column bg-1" style="border-radius: 1rem">
      <div v-if="!isloaded" class="fit column items-center justify-center">
        <q-spinner-ball color="primary" size="lg" v-if="!isloaded" />
      </div>
      <q-card
        flat
        class="full-width bg-2"
        style="border-top-left-radius: 1rem; border-top-right-radius: 1rem"
        v-else
      >
        <div
          class="column full-width justify-center items-center q-px-md q-pt-md q-mb-sm"
        >
          <div class="row full-width justify-center items-center">
            <div class="q-mr-xl" v-show="canchangetype === true">
              <q-btn-toggle
                v-model="filterFileType"
                :options="fileTypes"
                push
                style="min-width: 12rem"
                class="bg-0"
                :disable="uploadingFiles === true"
              >
                <template #library_img>
                  <div class="row items-center no-wrap">
                    <q-icon left name="image" />
                    <div class="text-center">Images</div>
                  </div>
                </template>

                <template #library_noise>
                  <div class="row items-center no-wrap">
                    <q-icon left name="music_note" />
                    <div class="text-center">Sounds</div>
                  </div>
                </template>

                <template #library_video>
                  <div class="row items-center no-wrap">
                    <q-icon left name="movie" />
                    <div class="text-center">Videos</div>
                  </div>
                </template>
              </q-btn-toggle>
            </div>
            <q-input
              :ref="'filterfile_' + id"
              v-model="filterFile"
              borderless
              type="text"
              :placeholder="$t('-raw-assets-search-file')"
              debounce="500"
              q-input
              style="
                border-radius: 0.5rem;
                border: 2px solid var(--q-2);
                box-shadow: 0 0 2rem var(--q-0);
                transition-duration: 0.2s;
                pointer-events: auto;
                min-width: 12rem;
              "
              class="bg-0 q-px-md col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"
            >
              <!-- width q-input col-xl-4,... important for authoring tab library-->
              <template #prepend>
                <q-icon name="search" />
              </template>
              <template #append>
                <q-icon
                  v-if="filterFile !== ''"
                  name="clear"
                  class="cursor-pointer"
                  @click="resetFilterFile"
                ></q-icon>
              </template>
            </q-input>
            <q-btn
              no-wrap
              class="bg-0 q-pa-md q-ml-sm col-auto"
              flat
              style="border-radius: 1rem; text-align: right"
              @click="showDialogAddFiles"
              :class="showAddFiles ? 'bg-p text-0' : 'text-p'"
              v-if="canupdate"
              :loading="loadingTranscode.size > 0"
            >
              <q-icon name="add" />
              {{ $t("-raw-assets-add-files") }}
            </q-btn>
            <!--
              Note: ref-c30073fd; record audio
              <q-btn
              no-wrap
              class="bg-0 q-pa-md q-ml-sm col-auto"
              flat
              style="border-radius: 1rem; text-align: right"
              @click="showDialogRecordFile"
              :class="showRecordFile ? 'bg-p text-0' : 'text-p'"
              v-if="canupdate"
              v-show="filterFileType == 'library_noise'"
            >
              <q-icon name="keyboard_voice" />
              {{ $t("-raw-assets-record-file") }}
            </q-btn> -->
          </div>
        </div>
        <!-- Note: ref-c30073fd; record audio -->
        <!-- <q-slide-transition
          class="column full-width q-px-md q-mb-sm col no-wrap"
          :style="{ 'max-height': `calc(${window.innerHeight}px - 17rem)` }"
        >
          <div v-show="showRecordFile && filterFileType == 'library_noise'">
            <player
              ref="ref_library_record"
              class="q-py-md"
              :data="{
                uid: 'library_record_' + newRecordUID,
                audiosamplerefid: null,
                objectid: null,
                objecttype: 'library',
                autoload: true,
                hideedit: false,
                hidedelete: false,
                hideeditaudio: false,
                hideeditannotations: true,
              }"
              :view="{
                color: 'v',
                time: true,
                reverse: true,
                spectro: true,
                height: 100,
                width: 300,
              }"
            />
          </div>
        </q-slide-transition> -->
        <q-slide-transition
          class="column full-width q-px-md q-mb-sm col no-wrap"
          :style="{ 'max-height': `calc(${window.innerHeight}px - 17rem)` }"
        >
          <div v-show="showAddFiles">
            <q-uploader
              class="full-width col"
              :accept="selectedFileType.extension_mask_"
              multiple
              @added="addFiles"
              :ref="'qUploadFile_' + id"
              :max-files="
                selectedFileType.max_parallel_upload
                  ? selectedFileType.max_parallel_upload
                  : 1000
              "
              :filter="filterUploader"
            >
              <template #header="scope">
                <div class="col bg-0 text-e">
                  <div class="row no-wrap items-center q-pa-sm q-gutter-xs">
                    <q-btn
                      :disable="!scope.canAddFiles || uploadingFiles === true"
                      type="a"
                      icon="add_box"
                      round
                      dense
                      flat
                      :ref="'qPickFile_' + id"
                    >
                      <q-uploader-add-trigger />
                      <q-tooltip
                        anchor="top right"
                        self="bottom right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        transition-show="jump-right"
                        transition-hide="jump-left"
                      >
                        {{ $t("-raw-assets-choose-files") }}
                      </q-tooltip>
                    </q-btn>
                    <q-btn
                      :disabled="
                        scope.queuedFiles.length > 0 && uploadingFiles === false
                          ? false
                          : true
                      "
                      icon="delete_forever"
                      @click="deleteFiles()"
                      round
                      dense
                      flat
                    >
                      <q-tooltip
                        v-if="scope.queuedFiles.length > 0"
                        anchor="top right"
                        self="bottom right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        transition-show="jump-right"
                        transition-hide="jump-left"
                      >
                        {{ $t("-raw-category-clear-all") }}
                      </q-tooltip>
                    </q-btn>
                    <div class="q-ml-md">
                      {{ $t("-raw-assets-upload-files") }} ({{
                        selectedFileType.extension_mask_
                      }})
                      <span v-if="selectedFileType.max_parallel_upload"
                        >{{ $t("-raw-assets-maximum-amount") }}
                        {{ scope.queuedFiles.length }}/{{
                          selectedFileType.max_parallel_upload
                        }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="row no-wrap items-center q-pl-md"
                    style="min-height: 3rem"
                  >
                    <span v-if="uploadGroupTicked.length == 0" class="text-n">{{
                      $t("-raw-assets-not-selected-group")
                    }}</span>
                    <span v-else class="text-italic">{{
                      $t("-raw-assets-selected-groups")
                    }}</span>
                    <div>
                      <q-btn
                        no-wrap
                        class="bg-0 q-pa-sm q-ml-sm"
                        flat
                        style="
                          border-radius: 1rem;
                          text-align: right;
                          width: 2.5rem;
                          height: 2.5rem;
                        "
                        v-if="
                          uploadGroup.length > 0 &&
                          uploadGroupTicked.length == 0
                        "
                        :disabled="uploadingFiles === true"
                      >
                        <q-icon name="more_horiz" />
                      </q-btn>
                      <div
                        class="text-v q-pl-md row"
                        style="cursor: pointer"
                        v-else
                      >
                        <div
                          v-for="(grv, gri) in uploadGroupTicked"
                          :key="gri + grv.ID"
                          class="row q-px-sm"
                        >
                          <div
                            v-for="(grv_, gri_) in grv.Names"
                            :key="'upl_' + gri_ + grv_"
                            class="text-no-wrap"
                          >
                            {{ grv_ }}
                            <q-icon
                              v-if="gri_ + 1 < grv.Names.length"
                              class="q-mx-xs"
                              name="double_arrow"
                              style="height: 1rem"
                            ></q-icon>
                            <template
                              v-else-if="gri + 1 < uploadGroupTicked.length"
                              >;</template
                            >
                          </div>
                        </div>
                      </div>
                      <q-menu
                        touch-position
                        @hide="updateGroupTicked"
                        fit
                        class="z-max"
                        style="border-radius: 1rem !important"
                      >
                        <q-input
                          filled
                          v-model="filterUploadGroup"
                          :ref="'filterUploadGroupRef_' + id"
                        >
                          <template #prepend>
                            <q-icon name="search" />
                          </template>
                          <template #append>
                            <q-icon
                              disable="filterUploadGroup === ''"
                              name="clear"
                              :class="
                                filterUploadGroup === '' ? '' : 'cursor-pointer'
                              "
                              @click="resetFilterUploadGroup"
                              :color="
                                filterUploadGroup === '' ? 'transparent' : ''
                              "
                            />
                          </template>
                        </q-input>
                        <div
                          class="column row items-end full-width"
                          v-show="!filterUploadGroupEmpty"
                        >
                          <q-btn
                            class="q-mr-md full-width"
                            flat
                            style="font-size: 0.8rem"
                            no-caps
                            :disable="
                              !(
                                uploadGroupTicked_ &&
                                uploadGroupTicked_.length > 0
                              )
                            "
                            @click="clearUploadGroupTicked_"
                          >
                            {{ $t("-raw-category-clear-all") }}
                          </q-btn>
                        </div>

                        <!-- Upload group tree -->
                        <q-tree
                          v-show="!filterUploadGroupEmpty"
                          :ref="'uploadtree_' + id"
                          :nodes="uploadGroup"
                          :filter="filterUploadGroup"
                          :filter-method="filterUploadGroupMethod"
                          label-key="Name"
                          node-key="ID"
                          children-key="Children"
                          v-model:ticked="uploadGroupTicked_"
                          tick-strategy="strict"
                          @update:ticked="onUpdateTickedUpload"
                          default-expand-all
                          class="column row q-mx-md"
                        >
                        </q-tree>
                        <div
                          v-show="filterUploadGroupEmpty"
                          class="column row items-center"
                        >
                          {{ $t("-raw-404-nothing-found-v2") }}
                        </div>
                      </q-menu>
                    </div>
                  </div>
                  <div
                    v-if="filesCount <= 0"
                    class="text-3 q-px-md q-py-lg justify-center items-center row text-center col"
                    style="font-size: 8rem; font-weight: 800"
                  >
                    {{ $t("-raw-drag-and-drop") }}
                  </div>
                  <div
                    v-if="filesCount <= 0"
                    class="text-7 q-px-md q-py-lg justify-center items-center row text-center col"
                    style="font-size: 4rem; font-weight: 800"
                  >
                    {{ selectedFileType.extension_mask_ }}
                  </div>
                </div>
              </template>
              <template #list>
                <div class="row wrap justify-center">
                  <!-- v-for="(item, index) in scope.files" -->
                  <div
                    :class="
                      filterFileType === 'library_video'
                        ? 'col-xl-4 col-lg-6 col-md-8 col-sm-12 col-xs-12 q-px-sm q-pb-md'
                        : 'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 q-px-sm q-pb-md'
                    "
                    v-for="(item, index) in files"
                    :key="'file_upload_' + index"
                    :style="
                      filterFileType === 'library_video'
                        ? 'width: 32rem'
                        : 'width: 16rem'
                    "
                  >
                    <div class="column fit">
                      <!-- <img style="max-width:220px; max-height:220px; border-width: 2px; border-style: solid;" :src="item.__img.src" class= "rounded-borders text-secondary"/>-->
                      <!--library_img-->
                      <q-img
                        v-if="item.type && item.type.split('/')[0] === 'image'"
                        :src="item.__img.src"
                        style="
                          min-height: 4rem;
                          max-height: 16rem;
                          border-radius: 1rem;
                        "
                        class="bg-0 col"
                        fit="contain"
                        @mouseenter="hoverItemID = index"
                        @mouseleave="hoverItemID = null"
                        @error="fileSetError(item)"
                      >
                        <!-- item name -->
                        <div
                          class="full-width absolute-top no-padding"
                          style="height: 2rem"
                        >
                          <div
                            class="fit row no-wrap items-center"
                            v-show="editingItem.ID !== item['__key']"
                          >
                            <q-scroll-area
                              class="row full-height items-center justify-center"
                              style="font-size: 0.8em; width: 15rem"
                            >
                              <div
                                class="row text-no-wrap items-center justify-center"
                                style="height: 2rem"
                              >
                                {{ fileInfo.get(item["__key"]).name }}
                              </div>
                            </q-scroll-area>
                            <q-btn
                              :disabled="uploadingFiles === true"
                              :style="
                                item['__key'] === hoverItemID
                                  ? 'opacity: 1'
                                  : 'opacity: 0'
                              "
                              class="bg-transparent transparent cursor-pointer"
                              flat
                              icon="edit"
                              round
                              size="xs"
                              style="cursor: default; width: 1rem; height: 1rem"
                              @click.stop="
                                editItem(
                                  item['__key'],
                                  fileInfo.get(item['__key']).name,
                                )
                              "
                            >
                              <q-tooltip
                                anchor="top right"
                                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                self="bottom right"
                                transition-hide="jump-left"
                                transition-show="jump-right"
                              >
                                {{ $t("-raw-general-edit-name") }}
                              </q-tooltip>
                            </q-btn>
                          </div>
                          <!--Name editor-->
                          <div
                            v-show="editingItem.ID === item['__key']"
                            class="row full-width no-wrap justify-center items-center"
                          >
                            <q-input
                              :ref="'edititem_' + item['__key']"
                              v-model="editingItem.name"
                              :readonly="isEditingItem"
                              borderless
                              class="bg-0 col edit-style"
                              dense
                              input-class="ubuntu text-center"
                              input-style="color: var(--q-a); font-size: 0.8rem; height: 2rem;"
                              style="
                                border-radius: 1rem;
                                font-size: 1rem;
                                height: 2.2rem;
                              "
                              type="text"
                              @keyup.enter="updateName(editingItem)"
                              @keyup.esc="editItemCancel"
                              @focus="(input) => input.target.select()"
                            />
                            <q-btn
                              :disable="!editingItem.name"
                              :loading="isEditingItem"
                              class="bg-0 edit-style"
                              flat
                              round
                              size="xs"
                              @click="updateName(editingItem)"
                            >
                              <q-icon color="p" name="done" />
                            </q-btn>
                            <q-btn
                              class="bg-0 edit-style"
                              flat
                              round
                              size="xs"
                              @click="editItemCancel"
                            >
                              <q-icon color="n" name="cancel" />
                            </q-btn>
                          </div>
                        </div>

                        <!-- delete and fullscreen buttons -->
                        <div
                          v-show="index === hoverItemID"
                          class="row absolute-bottom no-padding items-center"
                          style="height: 2rem"
                        >
                          <q-btn
                            :disable="
                              (filesStatus.get(item['__key']) &&
                                filesStatus.get(item['__key']) === 'loading') ||
                              uploadingFiles === true
                            "
                            class="q-ml-sm cursor-pointer"
                            color="negative"
                            dense
                            icon="delete"
                            round
                            size="sm"
                            style="cursor: default; height: 1rem; width: 1rem"
                            @click="deleteFiles(item['__key'])"
                          >
                            <q-tooltip
                              anchor="top right"
                              self="bottom right"
                              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                              transition-show="jump-right"
                              transition-hide="jump-left"
                            >
                              {{ $t("-raw-general-delete") }}
                            </q-tooltip>
                          </q-btn>
                          <q-btn
                            icon="fullscreen"
                            dense
                            round
                            :disable="
                              filesStatus.get(item['__key']) &&
                              filesStatus.get(item['__key']) === 'loading'
                            "
                            class="q-ml-sm cursor-pointer"
                            color="e"
                            size="sm"
                            style="cursor: default; height: 1rem; width: 1rem"
                            text-color="0"
                            @click.stop="showFullImageDialog(item.__img.src)"
                          >
                            <q-tooltip
                              anchor="top right"
                              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                              self="bottom right"
                              transition-hide="jump-left"
                              transition-show="jump-right"
                            >
                              {{ $t("-raw-general-zoom-in") }}
                            </q-tooltip>
                          </q-btn>
                        </div>
                      </q-img>
                      <!--library_noise-->
                      <div
                        class="bg-0 col column full-width"
                        v-else-if="
                          item.type &&
                          item.type.split('/')[0] === 'audio' &&
                          uploadSurfers.get(item['__key'])
                        "
                        style="
                          min-height: 4rem;
                          max-height: 16rem;
                          border-radius: 1rem;
                        "
                        @mouseenter="hoverItemID = index"
                        @mouseleave="hoverItemID = null"
                      >
                        <!-- <div>{{ fileInfo.get(item["__key"]).name }}</div> -->

                        <!-- item name -->
                        <div
                          class="full-width no-padding cursor-not"
                          style="height: 2rem"
                        >
                          <div
                            class="fit row no-wrap items-center"
                            v-show="editingItem.ID !== item['__key']"
                          >
                            <q-scroll-area
                              class="row full-height items-center justify-center"
                              style="font-size: 0.8em; width: 15rem"
                            >
                              <div
                                class="row text-no-wrap items-center justify-center"
                                style="height: 2rem"
                              >
                                {{ fileInfo.get(item["__key"]).name }}
                              </div>
                            </q-scroll-area>
                            <q-btn
                              :style="
                                index === hoverItemID
                                  ? 'opacity: 1'
                                  : 'opacity: 0'
                              "
                              color="e"
                              class="cursor-pointer bg-transparent"
                              flat
                              icon="edit"
                              round
                              size="xs"
                              style="cursor: default; width: 1rem; height: 1rem"
                              @click.stop="
                                editItem(
                                  item['__key'],
                                  fileInfo.get(item['__key']).name,
                                )
                              "
                            >
                              <q-tooltip
                                anchor="top right"
                                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                self="bottom right"
                                transition-hide="jump-left"
                                transition-show="jump-right"
                              >
                                {{ $t("-raw-general-edit-name") }}
                              </q-tooltip>
                            </q-btn>
                          </div>
                          <!--Name editor-->
                          <div
                            v-show="editingItem.ID === item['__key']"
                            class="row full-width no-wrap justify-center items-center"
                          >
                            <q-input
                              :ref="'edititem_' + item['__key']"
                              v-model="editingItem.name"
                              :readonly="isEditingItem"
                              borderless
                              class="col edit-style"
                              dense
                              input-class="ubuntu text-center bg-3"
                              input-style="color: var(--q-a); font-size: 0.8rem; height: 2rem; border-radius: 1rem;"
                              style="
                                border-radius: 1rem;
                                font-size: 1rem;
                                height: 2.2rem;
                              "
                              type="text"
                              @keyup.enter="updateName(editingItem)"
                              @keyup.esc="editItemCancel"
                              @focus="(input) => input.target.select()"
                            />
                            <q-btn
                              :disable="!editingItem.name"
                              :loading="isEditingItem"
                              class="bg-3 edit-style"
                              flat
                              round
                              size="xs"
                              @click="updateName(editingItem)"
                            >
                              <q-icon color="p" name="done" />
                            </q-btn>
                            <q-btn
                              class="bg-3 edit-style"
                              flat
                              round
                              size="xs"
                              @click="editItemCancel"
                            >
                              <q-icon color="n" name="cancel" />
                            </q-btn>
                          </div>
                        </div>

                        <div
                          :id="'surfer_' + uploadSurfers.get(item['__key']).id"
                        />
                        <!-- play and delete buttons -->
                        <div
                          class="row no-padding items-center"
                          style="height: 2rem"
                          v-if="filesStatus.get(item['__key']) !== 'error_read'"
                        >
                          <q-btn
                            :disable="
                              (filesStatus.get(item['__key']) &&
                                filesStatus.get(item['__key']) === 'loading') ||
                              uploadSurfers.get(item['__key']).status ===
                                'loading' ||
                              uploadingFiles === true
                            "
                            class="q-ml-sm"
                            color="negative"
                            dense
                            icon="delete"
                            round
                            size="sm"
                            style="cursor: default; height: 1rem; width: 1rem"
                            @click="deleteFiles(item['__key'])"
                          >
                            <q-tooltip
                              anchor="top right"
                              self="bottom right"
                              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                              transition-show="jump-right"
                              transition-hide="jump-left"
                            >
                              Delete
                            </q-tooltip>
                          </q-btn>
                          <q-btn
                            :disable="
                              (filesStatus.get(item['__key']) &&
                                filesStatus.get(item['__key']) === 'loading') ||
                              uploadSurfers.get(item['__key']).status ===
                                'loading'
                            "
                            class="q-ml-sm"
                            color="e"
                            text-color="0"
                            dense
                            :icon="
                              uploadSurfers.get(item['__key']).play == 0
                                ? 'play_arrow'
                                : uploadSurfers.get(item['__key']).play == 2
                                  ? 'replay'
                                  : 'pause'
                            "
                            round
                            size="sm"
                            style="cursor: default; height: 1rem; width: 1rem"
                            @click="
                              surferPlayOrStop(uploadSurfers.get(item['__key']))
                            "
                          >
                            <q-tooltip
                              anchor="top right"
                              self="bottom right"
                              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                              transition-show="jump-right"
                              transition-hide="jump-left"
                            >
                              <span
                                v-if="
                                  uploadSurfers.get(item['__key']).play == 1
                                "
                                >Pause</span
                              >
                              <span v-else>Play</span>
                            </q-tooltip>
                          </q-btn>
                        </div>
                      </div>
                      <!--library_video-->
                      <div
                        v-else-if="
                          item.type &&
                          item.type.split('/')[0] === 'video' &&
                          fileInfo.get(item['__key'])
                        "
                        :style="canchoose === true ? 'cursor: pointer' : ''"
                        class="bg-7 col relative-position"
                        style="
                          min-height: 8rem;
                          max-height: 32rem;
                          border-radius: 1rem;
                        "
                        @click="chooseItem(item)"
                        @mouseenter="hoverItemID = index"
                        @mouseleave="hoverItemID = null"
                      >
                        <div
                          v-if="!item.error_read"
                          class="full-width full-height"
                        >
                          <vue-plyr
                            :options="{
                              disableContextMenu: false,
                              hideControls: false,
                              clickToPlay: true,
                            }"
                          >
                            <video
                              class="full-height full-width"
                              controls
                              crossorigin
                              playsinline
                              preload="metadata"
                            >
                              <source
                                :src="fileInfo.get(item['__key']).urlObj"
                                @error="fileCheckProcessing(item, $event)"
                                type="video/mp4"
                              />
                            </video>
                          </vue-plyr>
                        </div>
                        <!-- item name -->
                        <div
                          class="full-width relative-position absolute-top no-padding cursor-not"
                          style="height: 2rem"
                        >
                          <div
                            v-show="editingItem.ID !== item['__key']"
                            class="fit row no-wrap items-center"
                          >
                            <q-scroll-area
                              class="row full-height items-center justify-center"
                              style="font-size: 0.8em; width: 100%"
                            >
                              <div
                                class="row text-no-wrap items-center justify-center"
                                style="height: 2rem"
                              >
                                {{ fileInfo.get(item["__key"]).name }}
                              </div>
                            </q-scroll-area>
                            <q-btn
                              :disabled="uploadingFiles === true"
                              :style="
                                item['__key'] === hoverItemID
                                  ? 'opacity: 1'
                                  : 'opacity: 0'
                              "
                              class="bg-transparent transparent cursor-pointer"
                              flat
                              icon="edit"
                              round
                              size="xs"
                              style="cursor: default; width: 1rem; height: 1rem"
                              @click.stop="
                                editItem(
                                  item['__key'],
                                  fileInfo.get(item['__key']).name,
                                )
                              "
                            >
                              <q-tooltip
                                anchor="top right"
                                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                                self="bottom right"
                                transition-hide="jump-left"
                                transition-show="jump-right"
                              >
                                {{ $t("-raw-general-edit-name") }}
                              </q-tooltip>
                            </q-btn>
                          </div>
                          <!--Name editor-->
                          <div
                            v-show="editingItem.ID === item['__key']"
                            class="row full-width no-wrap justify-center items-center"
                          >
                            <q-input
                              :ref="'edititem_' + item['__key']"
                              v-model="editingItem.name"
                              :readonly="isEditingItem"
                              borderless
                              class="bg-0 col edit-style"
                              dense
                              input-class="ubuntu text-center"
                              input-style="color: var(--q-a); font-size: 0.8rem; height: 2rem;"
                              style="
                                border-radius: 1rem;
                                font-size: 1rem;
                                height: 2.2rem;
                              "
                              type="text"
                              @focus="(input) => input.target.select()"
                              @keyup.enter="updateName(editingItem)"
                              @keyup.esc="editItemCancel"
                            />
                            <q-btn
                              :disable="!editingItem.name"
                              :loading="isEditingItem"
                              class="bg-0 edit-style"
                              flat
                              round
                              size="xs"
                              @click="updateName(editingItem)"
                            >
                              <q-icon color="p" name="done" />
                            </q-btn>
                            <q-btn
                              class="bg-0 edit-style"
                              flat
                              round
                              size="xs"
                              @click="editItemCancel"
                            >
                              <q-icon color="n" name="cancel" />
                            </q-btn>
                          </div>
                        </div>
                        <!-- delete button -->
                        <div
                          v-show="index === hoverItemID"
                          class="row absolute-top-left items-center"
                          style="height: 2rem"
                        >
                          <q-btn
                            :disable="
                              (filesStatus.get(item['__key']) &&
                                filesStatus.get(item['__key']) === 'loading') ||
                              uploadingFiles === true
                            "
                            class="cursor-pointer"
                            color="negative"
                            dense
                            icon="delete"
                            round
                            size="sm"
                            style="cursor: default; height: 1rem; width: 1rem"
                            @click.stop="deleteFiles(item['__key'])"
                          >
                            <q-tooltip
                              anchor="top right"
                              class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                              self="bottom right"
                              transition-hide="jump-left"
                              transition-show="jump-right"
                            >
                              {{ $t("-raw-general-delete") }}
                            </q-tooltip>
                          </q-btn>
                        </div>
                      </div>
                      <!-- HERE add other uploader type if need -->
                      <!-- <div class="bg-0 col column full-width" v-else>
                        {{ fileInfo.get(item["__key"]).name }}
                      </div> -->
                      <q-linear-progress
                        query
                        color="v"
                        v-if="
                          filesStatus.get(item['__key']) &&
                          filesStatus.get(item['__key']) === 'loading'
                        "
                      />
                      <div
                        class="full-width bg-1 row justify-center items-center"
                        v-else-if="
                          filesStatus.get(item['__key']) &&
                          filesStatus.get(item['__key']) === 'error_read'
                        "
                        style="height: 1.5rem; border-radius: 1rem"
                      >
                        <div style="cursor: default !important">
                          <q-icon color="n" size="1.3rem" name="error"></q-icon>
                          <span class="text-n q-pl-sm">Corrupted file</span>
                          <q-tooltip
                            anchor="top right"
                            self="bottom right"
                            class="mytooltip round-both ubuntu bg-0 row items-center q-px-md"
                            transition-show="jump-right"
                            transition-hide="jump-left"
                          >
                            {{ $t("-raw-assets-cant-read-file") }}
                            <span class="text-n text-italic q-ml-sm">{{
                              item.name
                            }}</span>
                          </q-tooltip>
                        </div>
                        <q-btn
                          flat
                          round
                          icon="close"
                          color="negative"
                          @click="deleteFiles(item['__key'])"
                          class="q-ml-sm cursor-pointer"
                          size="0.5rem"
                          :disable="
                            (filesStatus.get(item['__key']) &&
                              filesStatus.get(item['__key']) === 'loading') ||
                            uploadingFiles === true
                          "
                        >
                          <q-tooltip
                            anchor="top right"
                            self="bottom right"
                            class="mytooltip round-both ubuntu bg-0 row items-center q-px-md"
                            transition-show="jump-right"
                            transition-hide="jump-left"
                          >
                            {{ $t("-raw-assets-cancel-upload") }}
                          </q-tooltip>
                        </q-btn>
                      </div>
                      <div
                        class="full-width bg-1 row justify-center items-center"
                        v-else-if="
                          filesStatus.get(item['__key']) &&
                          filesStatus.get(item['__key'])?.error
                        "
                        style="height: 1.5rem; border-radius: 1rem"
                      >
                        <div style="cursor: default !important">
                          <q-icon color="n" size="1.3rem" name="error"></q-icon>
                          <span class="text-n q-pl-sm">Error</span>
                          <q-tooltip
                            anchor="top right"
                            self="bottom right"
                            class="mytooltip round-both ubuntu bg-0 text-e row items-center q-px-md"
                            transition-show="jump-right"
                            transition-hide="jump-left"
                          >
                            {{ filesStatus.get(item["__key"])?.error }}
                          </q-tooltip>
                        </div>
                      </div>
                      <div
                        class="full-width bg-1 row justify-center items-center"
                        v-else-if="
                          filesStatus.get(item['__key']) &&
                          filesStatus.get(item['__key'])?.success === true
                        "
                        style="height: 1.5rem; border-radius: 1rem"
                      >
                        <div style="cursor: default !important">
                          <q-icon color="p" size="1.3rem" name="check_circle">
                          </q-icon>
                          <span class="text-p q-pl-sm">Success</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </q-uploader>
            <div class="full-width column justify-center">
              <q-btn
                no-wrap
                class="bg-0 q-pa-md col-auto text-p"
                flat
                style="border-radius: 1rem; text-align: right"
                @click="checkUploadFiles"
                v-if="filesCount > 0"
                :loading="uploadingFiles"
                :disable="uploadingFiles || loadingTranscode.size > 0"
              >
                <q-icon name="upload" />
                {{ $t("-raw-assets-upload-files") }}
              </q-btn>
            </div>
          </div>
        </q-slide-transition>
      </q-card>

      <!--file list-->
      <q-scroll-area class="col q-pt-md" v-if="isloaded">
        <q-infinite-scroll
          :ref="'foundFilesScroll_' + id"
          class="row justify-center fit"
          @load="searchNextPage"
          :offset="200"
        >
          <div
            v-for="(item, index) in foundFiles"
            :key="'found_' + index + item.ID"
            :class="
              filterFileType === 'library_video'
                ? 'col-xl-4 col-lg-6 col-md-8 col-sm-12 col-xs-12 q-px-sm q-pb-md'
                : 'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 q-px-sm q-pb-md'
            "
            :style="
              filterFileType === 'library_video'
                ? 'max-width: 32rem'
                : 'max-width: 16rem'
            "
          >
            <!-- <img style="max-width:220px; max-height:220px; border-width: 2px; border-style: solid;" :src="item.__img.src" class= "rounded-borders text-secondary"/>-->

            <div
              class="column fit"
              :draggable="canupdate"
              @dragstart="startdrag(item)"
            >
              <!--library_img-->
              <q-img
                v-if="item.type_id && item.type_id === 'library_img'"
                :src="getItemSrc(item)"
                :style="canchoose === true ? 'cursor: pointer' : ''"
                class="bg-7 col"
                fit="contain"
                style="min-height: 4rem; max-height: 16rem; border-radius: 1rem"
                @click="chooseItem(item)"
                @mouseenter="hoverItemID = item.ID"
                @mouseleave="hoverItemID = null"
                @error="item.error_read = true"
              >
                <!-- selection overlay -->
                <div
                  v-show="item.ID === chosenItemID"
                  class="fit"
                  style="
                    border-color: #777fff;
                    border-style: solid;
                    border-radius: 1rem;
                    border-width: 4px;
                    background-color: #aaaaaa70;
                  "
                />
                <!-- item name -->
                <div
                  class="full-width absolute-top no-padding cursor-not"
                  style="height: 2rem"
                >
                  <div
                    v-show="editingItem.ID !== item.ID"
                    class="fit row no-wrap items-center"
                  >
                    <q-scroll-area
                      class="row full-height items-center justify-center"
                      style="font-size: 0.8em; width: 15rem"
                    >
                      <div
                        class="row text-no-wrap items-center justify-center"
                        style="height: 2rem"
                      >
                        {{ item.name }}
                      </div>
                    </q-scroll-area>
                    <q-btn
                      v-if="canupdate"
                      :style="
                        item.ID === hoverItemID && editingItem.ID !== item.ID
                          ? 'opacity: 1'
                          : 'opacity: 0'
                      "
                      class="bg-transparent transparent cursor-pointer"
                      flat
                      icon="edit"
                      round
                      size="xs"
                      style="cursor: default; width: 1rem; height: 1rem"
                      @click.stop="editItem(item.ID, item.name)"
                    >
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-general-edit-name") }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                  <!--Name editor-->
                  <div
                    v-show="canupdate && editingItem.ID === item.ID"
                    class="row full-width no-wrap justify-center items-center"
                  >
                    <q-input
                      :ref="'edititem_' + item.ID"
                      v-model="editingItem.name"
                      :readonly="isEditingItem"
                      borderless
                      class="bg-0 col edit-style"
                      dense
                      input-class="ubuntu text-center"
                      input-style="color: var(--q-a); font-size: 0.8rem; height: 2rem;"
                      style="
                        border-radius: 1rem;
                        font-size: 1rem;
                        height: 2.2rem;
                      "
                      type="text"
                      @keyup.enter="fileUpdateName(editingItem)"
                      @keyup.esc="editItemCancel"
                      @focus="(input) => input.target.select()"
                    />
                    <q-btn
                      :disable="!editingItem.name"
                      :loading="isEditingItem"
                      class="bg-0 edit-style"
                      flat
                      round
                      size="xs"
                      @click="fileUpdateName(editingItem)"
                    >
                      <q-icon color="p" name="done" />
                    </q-btn>
                    <q-btn
                      class="bg-0 edit-style"
                      flat
                      round
                      size="xs"
                      @click="editItemCancel"
                    >
                      <q-icon color="n" name="cancel" />
                    </q-btn>
                  </div>
                </div>
                <!-- drag indicator -->
                <div
                  v-if="canupdate"
                  v-show="
                    item.ID === hoverItemID &&
                    editingItem.ID !== item.ID &&
                    !item.error_read
                  "
                  class="q-ml-sm bg-transparent no-padding"
                  style="margin-top: 2.5rem"
                >
                  <q-icon
                    color="8"
                    class="bg-2 round-both q-pa-xs"
                    style="width: 1rem; height: 1rem"
                    name="drag_indicator"
                  >
                    <q-tooltip
                      anchor="bottom right"
                      self="top left"
                      class="mytooltip round-both ubuntu bg-0 row items-center q-px-md text-v"
                      transition-show="jump-up"
                      transition-hide="jump-down"
                    >
                      <b>{{ $t("-raw-assets-add-to-group") }}</b>
                    </q-tooltip>
                  </q-icon>
                </div>
                <!-- error banner -->
                <div
                  v-if="item.error_read"
                  class="bg-1 row absolute-center justify-center items-center no-padding full-width"
                  style="height: 1.5rem"
                >
                  <!-- processing -->
                  <div
                    v-if="item.processing"
                    style="cursor: default !important"
                  >
                    <q-icon
                      color="v"
                      name="hourglass_top"
                      size="1.3rem"
                    ></q-icon>
                    <span class="text-v q-pl-sm"
                      >{{ $t("-raw-assets-file-processing") }}
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 row items-center q-px-md text-v"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-assets-file-processing-tooltip") }}
                      </q-tooltip>
                    </span>
                    <q-btn
                      v-if="canupdate"
                      class="q-ml-sm cursor-pointer"
                      color="v"
                      dense
                      icon="delete"
                      round
                      size="sm"
                      style="cursor: default; height: 1rem; width: 1rem"
                      @click.stop="checkDeleteLibraryItem(item)"
                    >
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-general-delete") }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                  <!-- actual error -->
                  <div v-else style="cursor: default !important">
                    <q-icon color="n" name="error" size="1.3rem"></q-icon>
                    <span class="text-n q-pl-sm"
                      >{{ $t("-raw-assets-corrupted-file") }}
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 row items-center q-px-md text-v"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-assets-cant-read-file") }}
                        <span class="text-n text-italic q-ml-sm">{{
                          item.name
                        }}</span>
                      </q-tooltip>
                    </span>
                    <q-btn
                      v-if="canupdate"
                      class="q-ml-sm cursor-pointer"
                      color="negative"
                      dense
                      icon="delete"
                      round
                      size="sm"
                      style="cursor: default; height: 1rem; width: 1rem"
                      @click.stop="checkDeleteLibraryItem(item)"
                    >
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-general-delete") }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                </div>
                <!-- delete and fullscreen buttons -->
                <div
                  v-show="
                    item.ID === hoverItemID &&
                    editingItem.ID !== item.ID &&
                    !item.error_read
                  "
                  class="row absolute-bottom no-padding items-center"
                  style="height: 2rem"
                >
                  <q-btn
                    v-if="canupdate"
                    class="q-ml-sm cursor-pointer"
                    color="negative"
                    dense
                    icon="delete"
                    round
                    size="sm"
                    style="cursor: default; height: 1rem; width: 1rem"
                    @click.stop="checkDeleteLibraryItem(item)"
                  >
                    <q-tooltip
                      anchor="top right"
                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                      self="bottom right"
                      transition-hide="jump-left"
                      transition-show="jump-right"
                    >
                      {{ $t("-raw-general-delete") }}
                    </q-tooltip>
                  </q-btn>
                  <q-btn
                    class="q-ml-sm cursor-pointer"
                    color="e"
                    dense
                    icon="fullscreen"
                    round
                    size="sm"
                    style="cursor: default; height: 1rem; width: 1rem"
                    text-color="0"
                    @click.stop="showFullImageDialog(getItemSrc(item))"
                  >
                    <q-tooltip
                      anchor="top right"
                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                      self="bottom right"
                      transition-hide="jump-left"
                      transition-show="jump-right"
                    >
                      {{ $t("-raw-general-zoom-in") }}
                    </q-tooltip>
                  </q-btn>
                </div>
              </q-img>
              <!--library_noise-->
              <div
                v-else-if="item.type_id && item.type_id === 'library_noise'"
                class="bg-0 col column full-width"
                style="
                  min-height: 4rem;
                  max-height: 16rem;
                  border-radius: 1rem;
                  border-width: 4px;
                  border-style: solid;
                  border-color: #00000000;
                "
                @click="chooseItem(item)"
                @mouseenter="hoverItemID = item.ID"
                @mouseleave="hoverItemID = null"
                :style="
                  (item.ID === chosenItemID
                    ? `border-color: #777fff;
                    background-color: #aaaaaa70;`
                    : '') +
                  (canchoose === true && item.fileStatus !== 'error_read'
                    ? 'cursor: pointer'
                    : '')
                "
              >
                <!-- item name -->
                <div
                  class="full-width no-padding cursor-not"
                  style="height: 2rem"
                >
                  <div
                    v-show="editingItem.ID !== item.ID"
                    class="fit row no-wrap items-center"
                  >
                    <q-scroll-area
                      class="row full-height items-center justify-center"
                      style="font-size: 0.8em; width: 15rem"
                    >
                      <div
                        class="row text-no-wrap items-center justify-center"
                        style="height: 2rem"
                      >
                        {{ item.name }}
                      </div>
                    </q-scroll-area>
                    <q-circular-progress
                      indeterminate
                      v-show="item.surfer?.status === 'loading'"
                    />
                    <q-btn
                      v-if="canupdate"
                      :style="
                        item.ID === hoverItemID && editingItem.ID !== item.ID
                          ? 'opacity: 1'
                          : 'opacity: 0'
                      "
                      color="e"
                      class="cursor-pointer bg-transparent"
                      flat
                      icon="edit"
                      round
                      size="xs"
                      style="cursor: default; width: 1rem; height: 1rem"
                      @click.stop="editItem(item.ID, item.name)"
                    >
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-assets-edit-name") }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                  <!--Name editor-->
                  <div
                    v-show="canupdate && editingItem.ID === item.ID"
                    class="row full-width no-wrap justify-center items-center"
                  >
                    <q-input
                      :ref="'edititem_' + item.ID"
                      v-model="editingItem.name"
                      :readonly="isEditingItem"
                      borderless
                      class="col edit-style"
                      dense
                      input-class="ubuntu text-center bg-3"
                      input-style="color: var(--q-a); font-size: 0.8rem; height: 2rem; border-radius: 1rem;"
                      style="
                        border-radius: 1rem;
                        font-size: 1rem;
                        height: 2.2rem;
                      "
                      type="text"
                      @keyup.enter="fileUpdateName(editingItem)"
                      @keyup.esc="editItemCancel"
                      @focus="(input) => input.target.select()"
                    />
                    <q-btn
                      :disable="!editingItem.name"
                      :loading="isEditingItem"
                      class="bg-3 edit-style"
                      flat
                      round
                      size="xs"
                      @click="fileUpdateName(editingItem)"
                    >
                      <q-icon color="p" name="done" />
                    </q-btn>
                    <q-btn
                      class="bg-3 edit-style"
                      flat
                      round
                      size="xs"
                      @click="editItemCancel"
                    >
                      <q-icon color="n" name="cancel" />
                    </q-btn>
                  </div>
                </div>

                <div
                  v-show="item.fileStatus !== 'error_read'"
                  :id="'surfer_' + item.surfer.id"
                />
                <!-- error banner -->
                <div
                  v-if="item.fileStatus === 'error_read'"
                  class="full-width justify-center column"
                  style="height: 48px"
                >
                  <div
                    class="full-width bg-1 row justify-center items-center"
                    style="height: 1.5rem; border-radius: 1rem"
                  >
                    <div style="cursor: default !important">
                      <q-icon color="n" size="1.3rem" name="error"></q-icon>
                      <span class="text-n q-pl-sm">Corrupted file</span>
                      <q-tooltip
                        anchor="top right"
                        self="bottom right"
                        class="mytooltip round-both ubuntu bg-0 row items-center q-px-md"
                        transition-show="jump-right"
                        transition-hide="jump-left"
                      >
                        {{ $t("-raw-assets-cant-read-file") }}
                        <span class="text-n text-italic q-ml-sm">{{
                          item.name
                        }}</span>
                      </q-tooltip>
                    </div>
                    <q-btn
                      v-if="canupdate"
                      class="q-ml-sm cursor-pointer"
                      color="negative"
                      dense
                      icon="delete"
                      round
                      size="sm"
                      style="cursor: default; height: 1rem; width: 1rem"
                      @click.stop="checkDeleteLibraryItem(item)"
                    >
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-general-delete") }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                </div>
                <!-- play and delete buttons -->
                <div
                  class="row no-padding items-center"
                  style="height: 2rem"
                  v-if="item.fileStatus !== 'error_read'"
                >
                  <q-btn
                    v-if="canupdate"
                    :disable="
                      (item.fileStatus && item.fileStatus === 'loading') ||
                      item.surfer.status === 'loading'
                    "
                    class="q-ml-sm cursor-pointer"
                    color="negative"
                    dense
                    icon="delete"
                    round
                    size="sm"
                    style="cursor: default; height: 1rem; width: 1rem"
                    @click.stop="checkDeleteLibraryItem(item)"
                  >
                    <q-tooltip
                      anchor="top right"
                      self="bottom right"
                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                      transition-show="jump-right"
                      transition-hide="jump-left"
                    >
                      {{ $t("-raw-general-delete") }}
                    </q-tooltip>
                  </q-btn>
                  <q-btn
                    :disable="item.surfer.status === 'loading'"
                    class="q-ml-sm cursor-pointer"
                    color="e"
                    text-color="0"
                    dense
                    :icon="
                      item.surfer.play == 0
                        ? 'play_arrow'
                        : item.surfer.play == 2
                          ? 'replay'
                          : 'pause'
                    "
                    round
                    size="sm"
                    style="cursor: default; height: 1rem; width: 1rem"
                    @click="surferPlayOrStop(item.surfer)"
                  >
                    <q-tooltip
                      anchor="top right"
                      self="bottom right"
                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                      transition-show="jump-right"
                      transition-hide="jump-left"
                    >
                      <span v-if="item.surfer.play == 1">Pause</span>
                      <span v-else>Play</span>
                    </q-tooltip>
                  </q-btn>
                  <div class="col" />
                  <!-- drag indicator -->
                  <q-icon
                    v-if="canupdate"
                    v-show="
                      item.ID === hoverItemID &&
                      editingItem.ID !== item.ID &&
                      !item.error_read
                    "
                    color="8"
                    class="bg-2 round-both q-pa-xs q-mr-sm"
                    style="width: 1rem; height: 1rem"
                    name="drag_indicator"
                  >
                    <q-tooltip
                      anchor="bottom right"
                      self="top left"
                      class="mytooltip round-both ubuntu bg-0 row items-center q-px-md text-v"
                      transition-show="jump-up"
                      transition-hide="jump-down"
                    >
                      <b>{{ $t("-raw-assets-add-to-group") }}</b>
                    </q-tooltip>
                  </q-icon>
                </div>
              </div>
              <!--library_video-->
              <div
                v-else-if="item.type_id && item.type_id === 'library_video'"
                class="bg-7 col relative-position"
                style="min-height: 8rem; max-height: 32rem; border-radius: 1rem"
                :key="item.key"
                @click.prevent="chooseItem(item)"
                @mouseenter="hoverItemID = item.ID"
                @mouseleave="hoverItemID = null"
              >
                <div v-if="!item.error_read" class="full-width full-height">
                  <vue-plyr
                    :options="{
                      disableContextMenu: false,
                      hideControls: false,
                      clickToPlay: false,
                    }"
                  >
                    <video
                      class="full-height full-width"
                      controls
                      playsinline
                      preload="metadata"
                    >
                      <source
                        :src="getItemSrc(item)"
                        @error="fileCheckProcessing(item, $event)"
                        type="video/mp4"
                      />
                    </video>
                  </vue-plyr>
                </div>
                <!-- selection overlay -->
                <div
                  v-show="item.ID === chosenItemID"
                  class="fit absolute-full"
                  style="
                    border-color: #777fff;
                    border-style: solid;
                    border-radius: 1rem;
                    border-width: 4px;
                    background-color: #aaaaaa70;
                  "
                />
                <!-- item name -->
                <div
                  class="full-width relative-position absolute-top no-padding cursor-not"
                  style="height: 2rem"
                >
                  <div
                    v-show="editingItem.ID !== item.ID"
                    class="fit row no-wrap items-center"
                  >
                    <q-scroll-area
                      class="row full-height items-center justify-center"
                      style="font-size: 0.8em; width: 100%"
                    >
                      <div
                        class="row text-no-wrap items-center justify-center"
                        style="height: 2rem"
                      >
                        {{ item.name }}
                      </div>
                    </q-scroll-area>
                    <q-btn
                      v-if="canupdate"
                      :style="
                        item.ID === hoverItemID && editingItem.ID !== item.ID
                          ? 'opacity: 1'
                          : 'opacity: 0'
                      "
                      class="bg-transparent transparent cursor-pointer"
                      flat
                      icon="edit"
                      round
                      size="xs"
                      style="cursor: default; width: 1rem; height: 1rem"
                      @click.stop="editItem(item.ID, item.name)"
                    >
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-general-edit-name") }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                  <!--Name editor-->
                  <div
                    v-show="canupdate && editingItem.ID === item.ID"
                    class="row full-width no-wrap justify-center items-center"
                  >
                    <q-input
                      :ref="'edititem_' + item.ID"
                      v-model="editingItem.name"
                      :readonly="isEditingItem"
                      borderless
                      class="bg-0 col edit-style"
                      dense
                      input-class="ubuntu text-center"
                      input-style="color: var(--q-a); font-size: 0.8rem; height: 2rem;"
                      style="
                        border-radius: 1rem;
                        font-size: 1rem;
                        height: 2.2rem;
                      "
                      type="text"
                      @focus="(input) => input.target.select()"
                      @keyup.enter="fileUpdateName(editingItem)"
                      @keyup.esc="editItemCancel"
                    />
                    <q-btn
                      :disable="!editingItem.name"
                      :loading="isEditingItem"
                      class="bg-0 edit-style"
                      flat
                      round
                      size="xs"
                      @click="fileUpdateName(editingItem)"
                    >
                      <q-icon color="p" name="done" />
                    </q-btn>
                    <q-btn
                      class="bg-0 edit-style"
                      flat
                      round
                      size="xs"
                      @click="editItemCancel"
                    >
                      <q-icon color="n" name="cancel" />
                    </q-btn>
                  </div>
                </div>
                <!-- drag indicator -->
                <div
                  v-if="canupdate"
                  v-show="
                    item.ID === hoverItemID &&
                    editingItem.ID !== item.ID &&
                    !item.error_read
                  "
                  class="q-ml-sm bg-transparent no-padding absolute-top-right"
                  style="margin-top: 2.5rem"
                >
                  <q-icon
                    class="bg-2 round-both q-pa-xs"
                    color="8"
                    name="drag_indicator"
                    style="width: 1rem; height: 1rem"
                  >
                    <q-tooltip
                      anchor="bottom right"
                      class="mytooltip round-both ubuntu bg-0 row items-center q-px-md text-v"
                      self="top left"
                      transition-hide="jump-down"
                      transition-show="jump-up"
                    >
                      <b>{{ $t("-raw-assets-add-to-group") }}</b>
                    </q-tooltip>
                  </q-icon>
                </div>
                <!-- error banner -->
                <div
                  v-if="item.error_read || item.processing"
                  class="bg-1 row absolute-center justify-center items-center no-padding full-width"
                  style="height: 1.5rem"
                >
                  <!-- processing -->
                  <div
                    v-if="item.processing"
                    style="cursor: default !important"
                  >
                    <q-icon
                      color="v"
                      name="hourglass_top"
                      size="1.3rem"
                    ></q-icon>
                    <span class="text-v q-pl-sm"
                      >{{ $t("-raw-assets-file-processing") }}
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 row items-center q-px-md text-v"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-assets-file-processing-tooltip") }}
                      </q-tooltip>
                    </span>
                    <q-btn
                      v-if="canupdate"
                      class="q-ml-sm cursor-pointer"
                      color="v"
                      dense
                      icon="delete"
                      round
                      size="sm"
                      style="cursor: default; height: 1rem; width: 1rem"
                      @click.stop="checkDeleteLibraryItem(item)"
                    >
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-general-delete") }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                  <!-- actual error -->
                  <div v-else style="cursor: default !important">
                    <q-icon color="n" name="error" size="1.3rem"></q-icon>
                    <span class="text-n q-pl-sm"
                      >{{ $t("-raw-assets-corrupted-file") }}
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 row items-center q-px-md text-v"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-assets-cant-read-file") }}
                        <span class="text-n text-italic q-ml-sm">{{
                          item.name
                        }}</span>
                      </q-tooltip>
                    </span>
                    <q-btn
                      v-if="canupdate"
                      class="q-ml-sm cursor-pointer"
                      color="negative"
                      dense
                      icon="delete"
                      round
                      size="sm"
                      style="cursor: default; height: 1rem; width: 1rem"
                      @click.stop="checkDeleteLibraryItem(item)"
                    >
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-general-delete") }}
                      </q-tooltip>
                    </q-btn>
                    <q-btn
                      v-if="isSystem"
                      class="q-ml-sm cursor-pointer"
                      color="v"
                      dense
                      icon="refresh"
                      round
                      size="sm"
                      style="cursor: default; height: 1rem; width: 1rem"
                      @click.stop="reconvertLibraryItem(item)"
                    >
                      <q-tooltip
                        anchor="top right"
                        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                        self="bottom right"
                        transition-hide="jump-left"
                        transition-show="jump-right"
                      >
                        {{ $t("-raw-library-reconvert") }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                </div>
                <!-- delete button -->
                <div
                  v-show="
                    item.ID === hoverItemID &&
                    editingItem.ID !== item.ID &&
                    !item.error_read
                  "
                  class="row absolute-top-left items-center"
                  style="height: 2rem; gap: 1rem;"
                >
                  <q-btn
                    v-if="canupdate"
                    class="cursor-pointer"
                    color="negative"
                    dense
                    icon="delete"
                    round
                    size="sm"
                    style="cursor: default; height: 1rem; width: 1rem"
                    @click.stop="checkDeleteLibraryItem(item)"
                  >
                    <q-tooltip
                      anchor="top right"
                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                      self="bottom right"
                      transition-hide="jump-left"
                      transition-show="jump-right"
                    >
                      {{ $t("-raw-general-delete") }}
                    </q-tooltip>
                  </q-btn>
                  <q-btn
                    v-if="isSystem"
                    class="q-ml-sm cursor-pointer"
                    color="v"
                    dense
                    icon="refresh"
                    round
                    size="sm"
                    style="cursor: default; height: 1rem; width: 1rem"
                    @click.stop="reconvertLibraryItem(item)"
                  >
                    <q-tooltip
                      anchor="top right"
                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                      self="bottom right"
                      transition-hide="jump-left"
                      transition-show="jump-right"
                    >
                      {{ $t("-raw-library-reconvert") }}
                    </q-tooltip>
                  </q-btn>

                </div>
              </div>
              <!-- HERE add other uploader type if need -->
              <!-- <div class="bg-0 col column full-width" v-else>
                {{ item.name }}
              </div> -->

              <!-- categories -->
              <q-scroll-area
                class="text-v row full-width"
                style="cursor: pointer; height: 2rem"
              >
                <div
                  v-if="
                    uploadGroup.length > 0 && item.categories_list.length == 0
                  "
                  class="text-center"
                >
                  {{ $t("-raw-assets-no-group-selected") }}
                </div>
                <div
                  v-else
                  class="row no-wrap text-no-wrap full-width items-center justify-center"
                >
                  <div
                    v-for="(grv, gri) in item.categories_list"
                    :key="'x' + gri + grv.ID"
                    class="row no-wrap q-pr-sm"
                  >
                    <div
                      v-for="(grv_, gri_) in grv.Names"
                      :key="'xupl_' + gri_ + grv_"
                      class="row no-wrap items-center"
                    >
                      {{ grv_ }}
                      <q-icon
                        v-if="gri_ + 1 < grv.Names.length"
                        class="q-mx-xs"
                        name="double_arrow"
                      ></q-icon>
                      <div v-else-if="gri + 1 < item.categories_list.length">
                        ;
                      </div>
                    </div>
                  </div>
                </div>
                <q-menu
                  class="z-max"
                  fit
                  style="border-radius: 1rem !important"
                  touch-position
                  @hide="saveFileCategoriesLibrary(item)"
                  @show="fillGroupTickedFile(item)"
                >
                  <q-input
                    :ref="'filterBrowseGroupRef_' + id"
                    v-model="filterBrowseGroup"
                    filled
                  >
                    <template #prepend>
                      <q-icon name="search" />
                    </template>
                    <template #append>
                      <q-icon
                        :class="
                          filterBrowseGroup === '' ? '' : 'cursor-pointer'
                        "
                        :color="filterBrowseGroup === '' ? 'transparent' : ''"
                        disable="filterUploadGroupFile === ''"
                        name="clear"
                        @click="resetFilterBrowseGroup"
                      />
                    </template>
                  </q-input>
                  <div
                    class="column row items-end full-width"
                    v-show="!filterBrowseGroupEmpty"
                  >
                    <q-btn
                      :disable="
                        !(browseGroupTicked_ && browseGroupTicked_.length > 0)
                      "
                      class="q-mr-md full-width"
                      flat
                      no-caps
                      style="font-size: 0.8rem"
                      @click="clearBrowseGroupTicked_"
                    >
                      {{ $t("-raw-category-clear-all") }}
                    </q-btn>
                  </div>

                  <!-- Browse item group tree -->
                  <q-tree
                    v-show="!filterBrowseGroupEmpty"
                    :ref="'browsetree_' + id"
                    v-model:ticked="browseGroupTicked_"
                    :filter="filterBrowseGroup"
                    :filter-method="filterUploadGroupMethod"
                    :nodes="uploadGroup"
                    children-key="Children"
                    class="column row q-mx-md"
                    default-expand-all
                    label-key="Name"
                    node-key="ID"
                    tick-strategy="strict"
                    @update:ticked="onUpdateTickedBrowse"
                  >
                  </q-tree>
                  <div
                    v-show="filterBrowseGroupEmpty"
                    class="column row items-center"
                  >
                    {{ $t("-raw-discovery-nothing-found-v2") }}
                  </div>
                </q-menu>
              </q-scroll-area>
            </div>
          </div>
          <div
            v-if="!isSearchLoading && Object.keys(foundFiles).length === 0"
            class="row justify-center content-center full-width full-height ubuntu"
          >
            {{ $t("-raw-404-nothing-found-v2") }}
          </div>
        </q-infinite-scroll>
      </q-scroll-area>
    </div>
    <q-dialog v-model="showFullImage">
      <q-card
        :style="{
          width: `calc(${window.innerWidth * ($q.screen.lt.md ? 1 : 2 / 3)}px)`,
          'max-width': `calc(${
            window.innerWidth * ($q.screen.lt.md ? 1 : 2 / 3)
          }px)`,
        }"
      >
        <q-card-section>
          <q-btn
            v-close-popup
            class="bg-e"
            icon="close"
            no-caps
            style="
              position: absolute;
              right: 1rem;
              width: 2rem;
              height: 2rem;
              z-index: 9999 !important;
            "
            text-color="0"
          ></q-btn>
          <q-img
            v-if="showFullImage"
            :src="fullImageSrc"
            class="full-width"
            fit="contain"
          >
          </q-img>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { Notify, getCssVar } from "quasar";
import h from "src/h/helpers.js";
import api from "src/h/api.js";
// import {i18n} from "../../boot/i18n";
import WaveSurfer from "wavesurfer-old.js";
import { v4 as uuidv4 } from "uuid";
import encoderPath from "src/h/dist/encoderWorker.min.js?v=1";
import decoderPath from "src/h/dist/decoderWorker.min.js?v=1";
import wavePath from "src/h/dist/waveWorker.min.js";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import { toBlobURL } from "@ffmpeg/util";
import Lib from "src/h/helpers.js";

export default {
  emits: ["read", "save", "choose", "startdrag"],
  props: {
    pid: {
      type: String,
      default: "",
    },
    plibrary: {
      type: Object,
      default: function () {
        return {};
      },
    },
    pcanupdate: {
      type: Boolean,
      default: false,
    },
    pfilterfiletype: {
      type: String,
      default: null,
    },
    pcanchangetype: {
      type: Boolean,
      default: true,
    },
    pcanchoose: {
      type: Boolean,
      default: false,
    },
    pisfit: {
      type: Boolean,
      default: false,
    },
  },
  components: { VuePlyr },
  data: () => ({
    id: null,
    library: {},
    canupdate: false,
    canchangetype: true,
    canchoose: false,
    isFit: false,
    filterFile: "",
    filterFileType: null, // selected
    fileTypes: [],
    selectedFileType: {},
    isloaded: false,
    group: {},
    showAddFiles: false,
    files: {},
    filesStatus: new Map(),
    fileInfo: new Map(),
    filesCount: 0,
    isReloadFileList: false,
    uploadingFiles: false,
    uploadGroupMap: new Map(),
    uploadSurfers: new Map(),
    uploadGroup: [],
    uploadGroupTicked_: [], // tmp value in submenu
    uploadGroupTicked: [], // value after close menu
    filterUploadGroupEmpty: false,
    filterUploadGroup: "",
    browseGroupTicked_: [], // tmp value in submenu
    browseGroupTicked: [], // value after close menu
    filterBrowseGroupEmpty: false,
    filterBrowseGroup: "",
    foundFiles: {},
    isSearchScrollEnd: false,
    isSearchLoading: false,
    fnScrollDone: null, // q-infinite-scroll done function
    chosenItemID: null,
    hoverItemID: null,
    showFullImage: null,
    fullImageSrc: null,
    editingItem: {
      ID: null,
      name: null,
    },
    isEditingItem: false,
    encWorker: null,
    uploadOggFiles: new Map(), // ogg - converted files
    uploadFfmpegOggFiles: new Map(), // ogg - ffmpeg converted files (from mp3 etc)
    uploadOggFilesInfo: new Map(), // files info (like duration)
    uploadOggData: {}, // current file uploading
    jsBlobURL: null,
    wasmBlobURL: null,
    loadingTranscode: new Map(), // loading status for transcode
    browseWavWorkers: new Map(),
    browseDecoderWorkers: new Map(),
    requestId: null,
    // Note: ref-c30073fd; record audio
    // showRecordFile: false,
    // newRecordUID: uuidv4(),
  }),
  computed: {
    isSystem() {
      return !!Lib.getItem("VOC_USER_ROLE_IS_SYSTEM");
    },
  },
  watch: {
    filterFileType: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.selectedFileType = this.fileTypes.find(
            (e) => e.id === this.filterFileType,
          );
          if (oldVal) {
            this.deleteFiles();
          }
        }
        this.search();
      },
    },
    filterFile: {
      handler(newVal, oldVal) {
        this.search();
      },
    },
    filterUploadGroup: {
      handler(newVal, oldVal) {
        this.filterUploadGroupEmpty = !this.isTreeContainsName(
          this.uploadGroup,
          newVal,
        );
      },
    },
    filterBrowseGroup: {
      handler(newVal, oldVal) {
        this.filterBrowseGroupEmpty = !this.isTreeContainsName(
          this.uploadGroup,
          newVal,
        );
      },
    },
  },
  // Note: ref-c30073fd; record audio
  // beforeCreate: function () {
  //   // we need import player here like this because of Circular References Between Components Vue
  //   this.$options.components.player =
  //     require("src/components/player/index.vue").default;
  // },
  beforeMount() {
    this.id = this.pid;
    this.library = this.plibrary;
    this.canupdate = this.pcanupdate;
    this.canchoose = this.pcanchoose;
    this.isFit = this.pisfit;
    // this.filterFileType = this.pfilterfiletype; // can't do it here (need watcher work)
    if (this.pcanchangetype !== null && this.pcanchangetype !== undefined) {
      this.canchangetype = this.pcanchangetype;
    }
  },
  // beforeUnmount() {},
  mounted() {
    this.read({}, "select_file_type_library");
    this.jsBlobURL = toBlobURL(`ffmpeg-core.js`, "text/javascript");
    this.wasmBlobURL = toBlobURL(`ffmpeg-core.wasm`, "application/wasm");
  },
  unmounted() {
    if (this.encWorker) {
      this.encWorker.terminate();
    }
    this.filesStatus.clear();
    this.fileInfo.forEach((obj) => {
      if (obj.urlObj) {
        URL.revokeObjectURL(obj.urlObj);
      }
    });
    this.fileInfo.clear();
    this.uploadGroupMap.clear();
    this.destroyUploadSurfers(); // here we destory surfers from uploader
    this.uploadSurfers.clear();
    this.uploadOggFiles.clear();
    this.uploadOggFilesInfo.clear();
    if (this.jsBlobURL) {
      URL.revokeObjectURL(this.jsBlobURL);
    }
    if (this.wasmBlobURL) {
      URL.revokeObjectURL(this.wasmBlobURL);
    }
  },
  methods: {
    readText(buffer, start, length) {
      var a = new Uint8Array(buffer, start, length);
      var str = "";
      for (var i = 0; i < a.length; i++) {
        str += String.fromCharCode(a[i]);
      }
      return str;
    },
    readDecimal(buffer, start, length) {
      var a = new Uint8Array(buffer, start, length);
      return this.fromLittleEndianDecBytes(a);
    },
    fromLittleEndianDecBytes(a) {
      var sum = 0;
      for (var i = 0; i < a.length; i++) sum |= a[i] << (i * 8);
      return sum;
    },
    /**
     * Parse header of wav file
     *
     * @param {ArrayBuffer} buffer
     * @returns {{params: *[] | number, error: string | null}}
     */
    parseWavHeader(buffer) {
      const headerParams = [];
      const chunkID = this.readText(buffer, 0, 4);
      headerParams.push(chunkID);
      const chunkSize = this.readDecimal(buffer, 4, 4);
      headerParams.push(chunkSize);
      // if (chunkID !== 'RIFF') throw 'NOT_SUPPORTED_FORMAT';
      const format = this.readText(buffer, 8, 4);
      headerParams.push(format);
      // if (format !== 'WAVE') throw 'NOT_SUPPORTED_FORMAT';
      const compression = this.readDecimal(buffer, 20, 2);
      headerParams.push(compression);
      const numChannels = this.readDecimal(buffer, 22, 2);
      if (numChannels >= 2) {
        return { params: 0, error: `stereo .wav files not supported for now` };
      }
      headerParams.push(numChannels);
      const sampleRate = this.readDecimal(buffer, 24, 4);
      const allowedRates = [48000];
      if (!allowedRates.includes(sampleRate)) {
        return {
          params: 0,
          error: this.$t("-raw-file-is-not-wav-16bit-48000"),
        };
      }
      headerParams.push(sampleRate);
      // == SampleRate * NumChannels * BitsPerSample/8
      const byteRate = this.readDecimal(buffer, 28, 4);
      headerParams.push(byteRate);
      // == NumChannels * BitsPerSample/8
      const blockAlign = this.readDecimal(buffer, 32, 2);
      headerParams.push(blockAlign);
      const bitsPerSample = this.readDecimal(buffer, 34, 2);
      if (bitsPerSample !== 16) {
        return {
          params: 0,
          error: this.$t("-raw-file-is-not-wav-16bit-48000"),
        };
      }
      headerParams.push(bitsPerSample);
      return { params: headerParams, error: null };
    },
    fileCheckProcessing(item, event) {
      if (!event.target?.src) {
        this.fileSetError(item);
        return;
      }
      fetch(event.target.src, {
        method: "HEAD",
      }).then(
        (response) => {
          if (response.status === 202) {
            this.filesStatus.set(item["__key"], "processing");
            item.processing = true;
          } else {
            this.fileSetError(item);
          }
        },
        (error) => {
          this.fileSetError(item);
        },
      );
    },
    fileSetError(item) {
      this.filesStatus.set(item["__key"], "error_read");
      item.error_read = true;
    },
    save(data, method) {
      this.$emit("save", data, method);
    },
    fileChangeGroup(item) {
      this.save(
        {
          FileID: item.FileID,
          FileObjectID: item.FileObjectID,
          Groups: item.Groups, // array like [] {'id1/id2', 'id3'}
        },
        "file_group_change",
      );
    },
    // update name of item before upload
    updateName(item) {
      this.fileInfo.get(item.ID).name = item.name;
      this.editingItem = {};
    },
    // update name of uploaded item
    fileUpdateName(item) {
      this.isEditingItem = true;
      this.save(
        {
          FileObjectID: item.ID,
          Name: item.name,
        },
        "file_name_update",
      );
    },
    showDialogAddFiles() {
      this.showAddFiles = !this.showAddFiles;
      if (this.showAddFiles) {
        this.showRecordFile = false;
      }
      if (this.filesCount == 0 && this.showAddFiles) {
        // we only open dialog and have no any picked files
        let lnk = this;
        // setTimeout(() => {
        //   lnk.$refs["qUploadFile_" + lnk.id].pickFiles();
        // }, 50);
      }
    },
    showDialogRecordFile() {
      this.showRecordFile = !this.showRecordFile;
      if (this.showRecordFile) {
        this.showAddFiles = false;
      }
      // if (this.filesCount == 0 && this.showAddFiles) {
      //   // we only open dialog and have no any picked files
      //   let lnk = this;
      //   setTimeout(() => {
      //     lnk.$refs["qUploadFile_" + lnk.id].pickFiles();
      //   }, 50);
      // }
    },

    // we can have many files (tree, q-menu in UI), but only one model
    fillGroupTickedFile(item) {
      let ids = [];
      item.categories_library.forEach(function (item_) {
        let res = item_.split("/");
        ids.push(res[res.length - 1]);
      });
      this.browseGroupTicked_.splice(0, this.browseGroupTicked_.length, ...ids);
    },

    groupChanged(group) {
      if (this.group?.ID !== group?.ID) {
        // only if change group ID need search again
        this.group = group;
        this.search();
      } else {
        this.group = group;
      }
    },

    treeChanged(tree) {
      this.uploadGroup = tree;
      this.uploadGroupMap.clear();

      let lnk = this;
      let fndfnc = function (arr) {
        arr.forEach(function (item) {
          item.Names = [];
          lnk.uploadGroupMap.set(item.ID, item);
          if (item.Children) {
            fndfnc(item.Children);
          }
        });
      };
      fndfnc(this.uploadGroup);
      this.uploadGroupTicked.forEach(function (item, num) {
        let res = item.FullName.split("/");
        for (let i = 0; i < res.length; i++) {
          let fnd = lnk.uploadGroupMap.get(res[i]);
          if (fnd) {
            lnk.uploadGroupTicked[num].Names[i] = fnd.Name;
          } else {
            lnk.uploadGroupTicked.splice(num, 1);
            for (let j = 0; j < res.length; j++) {
              // remove from 'q-tree' for all (with parent too)
              let indexj = lnk.uploadGroupTicked_.indexOf(res[j]);
              if (indexj !== -1) {
                lnk.uploadGroupTicked_.splice(indexj, 1);
              }
            }
            break;
          }
        }
      });

      this.uploadGroupMap.forEach(function (item) {
        item.FullName.split("/").forEach(function (item_) {
          let ext = lnk.uploadGroupMap.get(item_);
          if (ext) {
            item.Names.push(ext.Name);
          }
        });
      });

      for (var i in this.foundFiles) {
        this.updateCategoriesList(this.foundFiles[i]);
      }
    },

    resetFilterUploadGroup() {
      this.filterUploadGroup = "";
      this.$refs["filterUploadGroupRef_" + this.id].focus();
    },

    resetFilterBrowseGroup() {
      this.filterBrowseGroup = "";
      this.$refs["filterBrowseGroupRef_" + this.id][0].focus();
    },

    isTreeContainsName(tree, name) {
      const lnk = this;
      const fnd = function (arr) {
        for (var i = 0; i < arr.length; i++) {
          if (lnk.filterUploadGroupMethod({ Name: arr[i].Name }, name))
            return true;
          if (arr[i].Children && arr[i].Children.length > 0) {
            if (fnd(arr[i].Children)) return true;
          }
        }
        return false;
      };

      return fnd(tree);
    },

    filterUploadGroupMethod(node, filter) {
      const filt = filter.toLowerCase();
      return node.Name && node.Name.toLowerCase().indexOf(filt) > -1;
    },

    clearUploadGroupTicked_() {
      this.$refs["uploadtree_" + this.id].setTicked(
        this.uploadGroupTicked_,
        false,
      );
    },

    clearBrowseGroupTicked_() {
      this.$refs["browsetree_" + this.id][0].setTicked(
        this.browseGroupTicked_,
        false,
      );
    },

    onUpdateTickedUpload(newTickedIDs) {
      this.updateGroupTicked_(
        newTickedIDs,
        "uploadtree_" + this.id,
        this.uploadGroupTicked_,
      );
    },
    onUpdateTickedBrowse(newTickedIDs) {
      this.updateGroupTicked_(
        newTickedIDs,
        "browsetree_" + this.id,
        this.browseGroupTicked_,
      );
    },

    // q-tree custom tick strategy to fully tick node parents on tick and untick children on untick
    updateGroupTicked_(newTickedIDs, elementRef, groupToUpdate) {
      let element = this.$refs[elementRef];
      if (!element) {
        return;
      }
      if (Array.isArray(element)) element = element[0];

      let nodes = element.getTickedNodes();
      if (newTickedIDs.length < nodes.length) {
        // untick node and all its children
        let lnk = this;
        let rnm = -1;
        let rm = function (arr) {
          if (arr.Children && arr.Children.length > 0) {
            for (let j = 0; j < arr.Children.length; j++) {
              // console.log(arr.Children[j].ID)
              let fnd = groupToUpdate.indexOf(arr.Children[j].ID);
              if (fnd !== -1) {
                groupToUpdate.splice(fnd, 1);
              }
              rm(arr.Children[j]);
            }
          }
        };

        for (let i = 0; i < nodes.length; i++) {
          rnm = newTickedIDs.indexOf(nodes[i].ID);
          if (rnm === -1) {
            // console.log('remove', nodes[i].ID)
            rm(nodes[i]);
          }
        }
      } else {
        // tick node and all its parents
        // select from tree from UI
        for (let i = 0; i < newTickedIDs.length; i++) {
          if (!element.isTicked(newTickedIDs[i])) {
            // console.log('add: ', target[i])
            let node = element.getNodeByKey(newTickedIDs[i]);
            if (
              node &&
              node.ParentID != null &&
              groupToUpdate.indexOf(node.ParentID) === -1
            ) {
              groupToUpdate.push(node.ParentID);
            }
          }
        }
      }
    },

    updateGroupTicked() {
      let ids = [];
      for (var i = 0; i < this.uploadGroupTicked_?.length; i++) {
        if (this.uploadGroupMap.get(this.uploadGroupTicked_[i])) {
          // this check current if map changed and uploadGroupTicked_ have some undefined values (after delete group)
          ids.push(this.uploadGroupTicked_[i]);
        }
      }

      const nm = this.getTreeNodes(this.uploadGroup, this.uploadGroupMap, ids);
      this.uploadGroupTicked.splice(0, this.uploadGroupTicked.length, ...nm);
    },

    // get array of tree nodes by node IDs. Only the deepest nodes are returned, throwing away parent IDs
    getTreeNodes(tree, treeMap, IDs) {
      // check order
      var nm = [];
      var fnd = function (arr) {
        for (var i = 0; i < arr.length; i++) {
          if (IDs.indexOf(arr[i].ID) !== -1) {
            // arr[i].Names = [arr[i].Name];
            nm.push(arr[i]);
          }
          if (arr[i].Children && arr[i].Children.length > 0) {
            fnd(arr[i].Children);
          }
        }
      };
      fnd(tree);

      // show only last values in tree (last children)
      // for example: x1; x1/x2; x1/x2/x3 -> show only x1/x2/x3
      for (var i = 1; i < nm.length; i++) {
        // console.log('nm: ', nm[i].FullName, nm[i-1].FullName)
        if (nm[i].FullName.indexOf(nm[i - 1].FullName) === 0) {
          // console.log("DEL: ", nm[i - 1]);
          nm.splice(i - 1, 1);
          i--;
        }
      }

      for (var i = 0; i < nm.length; i++) {
        let ext = treeMap.get(nm[i].ID);
        if (ext) {
          nm[i].Names = ext.Names;
        }
      }

      return nm;
    },

    resetFilterFile() {
      this.filterFile = "";
      this.$refs["filterfile_" + this.id].focus();
    },

    read(data, method, requestid) {
      this.$emit("read", data, method, requestid);
    },

    search() {
      if (!this.isloaded) {
        return;
      }
      // Need to clear wavesurfer's JSArrayBufferData to avoid memory leak
      for (let member in this.foundFiles) {
        if (this.foundFiles[member]?.surfer?.surfer) {
          this.foundFiles[member].surfer.surfer.destroy();
        }
        delete this.foundFiles[member];
      }
      for (let worker in this.browseWavWorkers) worker.terminate();
      for (let worker in this.browseDecoderWorkers) worker.terminate();
      this.requestId = uuidv4();
      this.isSearchScrollEnd = false;
      this.$refs["foundFilesScroll_" + this.id]?.reset();
      this.$refs["foundFilesScroll_" + this.id]?.resume();
      this.$refs["foundFilesScroll_" + this.id]?.trigger();
    },
    syncSearch(data, objname, isncurrentopt) {
      switch (objname) {
        case "insert_library_file":
          if (this.isInCurrentCategory(data.categories_library)) {
            this.addBrowseFile(data);
          } else {
            this.updateCategoriesList(data);
          }
          break;
        case "file_group_change":
          {
            const file = this.foundFiles[data.FileObjectID];
            if (file) {
              file.categories_library = data.Groups;
              if (!this.isInCurrentCategory(data.Groups)) {
                this.deleteBrowseFile(data.FileObjectID);
              } else {
                this.updateCategoriesList(file);
              }
            }
          }
          break;
        case "file_delete":
          this.deleteBrowseFile(data.FileObjectID);
          break;
        case "file_name_update":
          {
            const file = this.foundFiles[data.FileObjectID];
            if (file) {
              file.name = data.Name;
              this.isEditingItem = false;
              this.editingItem = {};
            }
          }
          break;
      }
    },

    sync(data, objname, isncurrentopt, requestid) {
      switch (objname) {
        case "select_file_type_library":
          // we store in DB mask like 'jpeg' but for ui need '.jpeg' (for 'q-uploader' component, check accept)
          // also, it might look like 'audio/wav', which doesn't need dot
          let data_ = [];
          data.forEach(function (item) {
            item.extension_mask_ = (item.extension_mask || "")
              .split(",")
              .map((substring) =>
                substring.startsWith(".") || substring.includes("/")
                  ? substring
                  : `.${substring}`,
              )
              .join(",");
            data_.push(item);
          });
          this.fileTypes = data_.map((item) => ({
            ...item,
            value: item.id,
            slot: item.id,
          }));
          if (this.pfilterfiletype === null) {
            // if no any type selected, select first type
            // test: 0-images, 1-noise
            this.filterFileType = this.fileTypes[0].id;
            // this.filterFileType = this.fileTypes[1].id;
          } else {
            this.filterFileType = this.pfilterfiletype;
          }
          let lnk = this;
          setTimeout(() => {
            // need remove twice select (watch for this.filterFileType)
            lnk.isloaded = true;
            lnk.search();
          }, 50);
          break;
        case "search_file_library":
          if (!data) {
            data = [];
          }
          // omit data from previous requests
          if (this.requestId === requestid) {
            data.forEach((item) => {
              this.addBrowseFile(item);
            });
            if (data.length === 0) this.isSearchScrollEnd = true;
          }
          if (this.fnScrollDone) this.fnScrollDone(this.isSearchScrollEnd);
          this.isSearchLoading = false;
          break;
      }
    },

    // make item.categories_list from uploadGroup using ids in item.categories_library
    updateCategoriesList(item) {
      if (!item.categories_library) {
        item.categories_library = [];
      }

      // take only last parts of full_name
      let lbIds = [];
      item.categories_library.forEach(function (item_) {
        let it = item_.split("/");
        lbIds.push(it[it.length - 1]);
      });

      let ids = [];
      for (let i = 0; i < lbIds?.length; i++) {
        if (this.uploadGroupMap.get(lbIds[i])) {
          ids.push(lbIds[i]);
        }
      }

      const nm = this.getTreeNodes(this.uploadGroup, this.uploadGroupMap, ids);

      if (item.categories_list === null || item.categories_list === undefined) {
        item.categories_list = [];
      }
      item.categories_list.splice(0, item.categories_list.length, ...nm);
    },
    saveFileCategoriesLibrary(item) {
      // browseGroupTicked_ contains only ticked IDs (like x1, x2, x3), we need full names with path (x1, x1/x2, x1/x2/x3)
      const lnk = this;
      let fullNames = [];
      let fnd = function (arr) {
        for (var i = 0; i < arr.length; i++) {
          if (lnk.browseGroupTicked_.indexOf(arr[i].ID) !== -1) {
            fullNames.push(arr[i].FullName);
          }
          if (arr[i].Children && arr[i].Children.length > 0) {
            fnd(arr[i].Children);
          }
        }
      };
      fnd(this.uploadGroup);
      // Do not change model, it will be updated on WS response
      this.fileChangeGroup({
        FileID: item.file_id,
        FileObjectID: item.ID,
        Groups: fullNames,
      });
    },
    addFiles(files) {
      // add some files into list (so can't clear all 'filesStatus' and 'fileInfo')
      for (let i = 0; i < files.length; i++) {
        if (this.filesStatus.get(files[i]["__key"])) {
          this.filesStatus.delete(files[i]["__key"]);
        }
        if (this.fileInfo.get(files[i]["__key"])) {
          if (this.fileInfo.get(files[i]["__key"]).urlObj) {
            URL.revokeObjectURL(this.fileInfo.get(files[i]["__key"]).urlObj);
          }
          this.fileInfo.delete(files[i]["__key"]);
        }
        let name = files[i].name;
        if (name.lastIndexOf(".") > -1) {
          name = name.substring(0, name.lastIndexOf("."));
        }
        let urlObj = "";
        if (this.selectedFileType.mime_type === "video") {
          urlObj = URL.createObjectURL(files[i], { oneTimeOnly: true });
        }
        this.fileInfo.set(files[i]["__key"], { name: name, urlObj: urlObj });
        this.files[files[i]["__key"]] = files[i];
      }
      this.filesCount = Object.keys(this.files).length;

      // init surfers (only for audio)
      if (this.selectedFileType.mime_type === "audio") {
        for (let i = 0; i < files.length; i++) {
          if (!this.uploadSurfers.get(files[i]["__key"])) {
            let obj = {
              id: uuidv4(),
              status: "loading",
              urlobj: URL.createObjectURL(files[i], { oneTimeOnly: true }),
              play: 0,
            };
            this.uploadSurfers.set(files[i]["__key"], obj);

            let params = {
              container: "#surfer_" + obj.id,
              barWidth: 3,
              barMinHeight: 1,
              barGap: 3,
              barRadius: 3,
              cursorColor: getCssVar("v"),
              progressColor: getCssVar("v"),
              waveColor: getCssVar("e"),
              height: 48,
              normalize: true,
              responsive: 100,
              cursorWidth: 2,
              plugins: [],
            };

            let pnt = this;
            setTimeout(() => {
              let surfer = new WaveSurfer(params);
              surfer.init();

              surfer.load(obj.urlobj);

              surfer.on("ready", async () => {
                pnt.uploadSurfers.get(files[i]["__key"]).status = "ready";
              });

              surfer.on("error", async () => {
                pnt.fileSetError(files[i]);
                pnt.uploadSurfers.get(files[i]["__key"]).status = "ready";
              });

              surfer.on("finish", async () => {
                pnt.uploadSurfers.get(files[i]["__key"]).play = 2;
              });

              surfer.on("seek", async () => {
                if (pnt.uploadSurfers.get(files[i]["__key"]).play === 2) {
                  pnt.uploadSurfers.get(files[i]["__key"]).play = 0;
                }
              });

              pnt.uploadSurfers.get(files[i]["__key"]).surfer = surfer;

              // let filereader = new FileReader();
              // filereader.readAsArrayBuffer(files[i]);
              // filereader.onload = function () {
              //   console.log("1 ", filereader.result);
              // };
              // filereader.onerror = function () {
              //   console.log("2 ", filereader.error);
              // };
            }, 50);
          }
        }
      }
    },
    errorNotification(msg) {
      Notify.create({
        group: true,
        timeout: 10000,
        message: msg,
        color: "1",
        textColor: "n",
        classes: "round-both q-ml-lg q-mb-sm",
        position: "bottom",
      });
    },
    filterUploader(files, isRepeat) {
      return (
        files
          // Notify if we have more files than max_parallel_upload
          .filter(() => {
            if (
              this.selectedFileType.max_parallel_upload &&
              files.length > this.selectedFileType.max_parallel_upload
            ) {
              this.errorNotification(
                "The maximum number of files is " +
                  this.selectedFileType.max_parallel_upload,
              );
              return false;
            }
            return true;
          })
          // Check file size
          .filter((file) => {
            if (
              this.selectedFileType.max_size &&
              file.size > this.selectedFileType.max_size
            ) {
              this.errorNotification(
                "The maximum file size is " +
                  Math.round(this.selectedFileType.max_size / 1000, 0) +
                  " kB",
              );
              return false;
            } else {
              return true;
            }
          })
          // Check file mime type
          .filter((file) => {
            if (
              this.selectedFileType.mime_type &&
              this.selectedFileType.mime_type !== file.type.split("/")[0]
            ) {
              this.errorNotification(
                "The signature of some files are incorrect. Need " +
                  this.selectedFileType.mime_type +
                  " file",
              );
              return false;
            } else {
              return true;
            }
          })
          // For wav we need to check header, but it can only be done asynchronously,
          // so we return false for the filter and after resolve we add files manually
          .filter((file) => {
            if (this.selectedFileType.mime_type === "audio") {
              if (file.type === "audio/wav") {
                file
                  .slice(0, 44)
                  .arrayBuffer()
                  .then((buffer) => {
                    const err = this.parseWavHeader(buffer)?.error;
                    if (!err) {
                      this.addFiles([file]);
                      return;
                    }
                    // error in header, try to transcode and rerun filter. isRepeat prevents infinite loop
                    if (isRepeat) {
                      this.errorNotification(err);
                      return;
                    }
                    h.transcode(
                      URL.createObjectURL(file),
                      file.name,
                      "wav",
                      this.loadingTranscode,
                      false,
                      this.jsBlobURL,
                      this.wasmBlobURL,
                    ).then((fileArray) => {
                      const blob = new Blob([fileArray], { type: "audio/wav" });
                      const dstFile = new File([blob], file.name, {
                        type: "audio/wav",
                      });
                      dstFile.__key = file.__key;
                      this.filterUploader([dstFile], true);
                    });
                  });
                return false;
              } else {
                // audio, not wav - transcode both to WAV and OGG and retry filter for wav to see if the header is ok
                h.transcode(
                  URL.createObjectURL(file),
                  file.name,
                  "wav",
                  this.loadingTranscode,
                  false,
                  this.jsBlobURL,
                  this.wasmBlobURL,
                ).then((fileArray) => {
                  const blob = new Blob([fileArray], { type: "audio/wav" });
                  const dstFile = new File([blob], file.name, {
                    type: "audio/wav",
                  });
                  dstFile.__key = file.__key;
                  this.filterUploader([dstFile], true);

                  // put ogg to uploadFfmpegOggFiles
                  h.transcode(
                    URL.createObjectURL(file),
                    file.name,
                    "ogg",
                    this.loadingTranscode,
                    false,
                    this.jsBlobURL,
                    this.wasmBlobURL,
                  ).then((fileArray) => {
                    this.uploadFfmpegOggFiles.set(file.__key, fileArray);
                  });
                });
                return false;
              }
            } else {
              // non-audio
              return true;
            }
          })
      );
    },
    deleteFiles(key) {
      if (key === undefined || key === null) {
        if (this.$refs["qUploadFile_" + this.id]) {
          this.$refs["qUploadFile_" + this.id].reset();
        }
        this.files = {};
        this.filesCount = 0;

        // clear surfers if exists
        // it's important after clear file and try load the same file again
        this.destroyUploadSurfers();
        return;
      }

      if (this.files[key]) {
        var pnt = this;
        let sfk = pnt.uploadSurfers.get(key);
        if (sfk) {
          sfk.surfer.destroy();
          URL.revokeObjectURL(sfk.urlobj);
          pnt.uploadSurfers.delete(key);
          // document.getElementById("surfer_" + sfk.id).remove();
        }

        this.$refs["qUploadFile_" + this.id].removeFile(this.files[key]);
        delete this.files[key];
      }
      this.filesCount = Object.keys(this.files).length;
    },
    surferPlayOrStop(surfer) {
      if (surfer) {
        if (surfer.play == 0 || surfer.play == 2) {
          surfer.surfer.play();
          surfer.play = 1;
        } else {
          surfer.surfer.pause();
          surfer.play = 0;
        }
      }
    },
    destroyUploadSurfers() {
      let surfiterator = this.uploadSurfers.keys();
      let v = surfiterator.next().value;
      while (v) {
        let sfk = this.uploadSurfers.get(v).surfer;
        sfk.destroy();
        URL.revokeObjectURL(sfk.urlobj);
        v = surfiterator.next().value;
      }
      this.uploadSurfers.clear();
    },
    checkDeleteLibraryItem(item) {
      let pnt = this;
      api.CallConfirmation(
        {
          method: "warning",
          icon: "delete",
          buttonOk: "Delete",
          message: "Are you sure you want to delete the file?",
        },
        () => {
          return new Promise((resolve, reject) => {
            pnt.save({ FileObjectID: item.ID }, "file_delete");
            resolve();
          });
        },
      );
    },
    reconvertLibraryItem(item) {
      return api
        .Call({
          url: "/api/v1/video_converter/" + item?.file_id + "?IsWait=true&overwrite=true",
          method: "put",
          show_error: true,
        })
        .then(
          (response) => {
            this.$q.notify({
              type: "positive",
              message: "Done converting: " + item.name,
              classes: "round-both q-mb-sm no-shadow ubuntu",
            });
            item.error_read = false;
            item.key = uuidv4();
          },
          (e) => {
            console.error("error-976b2e4d", "re-convert video", e);
            throw e;
          },
        );
    },
    checkUploadFiles() {
      if (!this.uploadGroupTicked || this.uploadGroupTicked.length === 0) {
        let pnt = this;
        api.CallConfirmation(
          {
            method: "warning",
            icon: "upload",
            buttonOk: "Upload",
            message: this.$t("-raw-assets-not-selected-group-cont"),
          },
          () => {
            return new Promise((resolve, reject) => {
              pnt.uploadFiles();
              resolve();
            });
          },
        );
      } else {
        this.uploadFiles();
      }
    },
    chunkBuffers(arrayBuffer, chunkLength) {
      let chunkedBuffers = [];
      let totalFile = new Int16Array(arrayBuffer);
      // Skip wave header; 44 bytes
      for (let i = 22; i < totalFile.length; i += chunkLength) {
        // Convert 16 bit signed int to 32bit float
        let bufferChunk = new Float32Array(chunkLength);
        for (let j = 0; j < chunkLength; j++) {
          // bufferChunk[j] = (totalFile[i + j - 22] + 0.5) / 32767.5
          bufferChunk[j] = (totalFile[i + j] + 0.5) / 32767.5;
        }
        chunkedBuffers.push([bufferChunk]);
      }
      return chunkedBuffers;
    },
    runUploadEncWorker(file) {
      // File can already be transcoded
      if (this.uploadFfmpegOggFiles.has(file.__key)) {
        return;
      }
      new Response(file).arrayBuffer().then((buffer) => {
        // console.warn("response file buf", buffer);
        // get header bytes
        const header = buffer.slice(0, 44); // NOTE: ref-b99edf5b
        // we already checked the files before add in upload-list (header)

        const sampleRateTest = this.parseWavHeader(header).params[5];
        const byteRateTest = this.parseWavHeader(header).params[6];

        // console.log("XXX upload Sample Rate: ", sampleRateTest);
        // console.log("XXX upload Byte Rate: ", byteRateTest);

        this.uploadOggFilesInfo.set(this.uploadOggData.key, {
          length: buffer.byteLength / 48000 / 2,
        });

        this.encWorker.postMessage({
          command: "init",
          bufferLength: 4096,
          originalSampleRate: sampleRateTest,
          encoderBitRate: byteRateTest,
        });
        this.encWorker.postMessage({
          command: "getHeaderPages",
        });
        this.chunkBuffers(buffer, 4096).forEach((bufferChunk) => {
          // console.log(bufferChunk)
          this.encWorker.postMessage({
            command: "encode",
            buffers: bufferChunk,
          });
        });
        this.encWorker.postMessage({
          command: "done",
        });
      });
    },
    setupBrowseWorkers(fileObjectID) {
      const wavWorker = new Worker(wavePath);
      const decoderWorker = new Worker(decoderPath);
      const pnt = this;
      wavWorker.onmessage = (e) => {
        if (e.data.message === "page") {
          const item = pnt.foundFiles[fileObjectID];
          if (item?.surfer?.surfer) {
            let blob = URL.createObjectURL(
              new Blob([e.data.page], { type: "audio/wav" }),
            );
            item.surfer.surfer.load(blob);
            URL.revokeObjectURL(blob);
          }
          wavWorker.terminate();
          decoderWorker.terminate();
          pnt.browseWavWorkers.delete(fileObjectID);
          pnt.browseDecoderWorkers.delete(fileObjectID);
        }
      };

      decoderWorker.onmessage = (e) => {
        if (e.data === null) {
          wavWorker.postMessage({ command: "done" });
        } else {
          wavWorker.postMessage(
            {
              command: "encode",
              buffers: e.data,
            },
            e.data.map((typedArray) => typedArray.buffer),
          );
        }
      };
      this.browseWavWorkers.set(fileObjectID, wavWorker);
      this.browseDecoderWorkers.set(fileObjectID, decoderWorker);
    },
    loadAudioAsset(fileObjectID) {
      const pnt = this;
      return api
        .Call({
          url:
            "/api/v1/asset/file/library_noise/" +
            fileObjectID +
            encodeURI("?content_type=audio/mpeg"),
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "audio/ogg",
          },
          getHeaders: true,
        })
        .then(
          (response) => {
            // console.log("content-type", response.headers["content-type"]);
            if (response.headers["content-type"] === "audio/mpeg") {
              const item = pnt.foundFiles[fileObjectID];
              const blobURL = URL.createObjectURL(
                new Blob([response.data], { type: "audio/mpeg" }),
              );
              item.surfer.surfer.load(blobURL);
              URL.revokeObjectURL(blobURL);
            } else {
              pnt.setupBrowseWorkers(fileObjectID);
              const wavWorker = pnt.browseWavWorkers.get(fileObjectID);
              const decoderWorker = pnt.browseDecoderWorkers.get(fileObjectID);

              let typedArray = new Uint8Array(response.data);
              wavWorker.postMessage({ command: "init", wavSampleRate: 48000 });
              decoderWorker.postMessage({ command: "init" });
              decoderWorker.postMessage(
                {
                  command: "decode",
                  pages: typedArray,
                },
                [typedArray.buffer],
              );
              // On corrupted files decoder is silent, let's force it to return anything that is not decoded
              decoderWorker.postMessage({ command: "done" });
            }
          },
          (e) => {
            if (pnt.foundFiles[fileObjectID]) {
              pnt.foundFiles[fileObjectID].fileStatus = "error_read";
              pnt.browseWavWorkers.get(fileObjectID)?.terminate();
              pnt.browseDecoderWorkers.get(fileObjectID)?.terminate();
            }
            console.error("error-814c56ae", "loadAudioAsset", e);
            throw e;
          },
        );
    },
    initBrowseSurfer(fileObjectID) {
      if (!this.foundFiles[fileObjectID].surfer) {
        let obj = {
          id: uuidv4(),
          // status: "loading",
          play: 0,
        };
        this.foundFiles[fileObjectID].surfer = obj;

        let params = {
          container: "#surfer_" + obj.id,
          barWidth: 3,
          barMinHeight: 1,
          barGap: 3,
          barRadius: 3,
          cursorColor: getCssVar("v"),
          progressColor: getCssVar("v"),
          waveColor: getCssVar("e"),
          height: 48,
          normalize: true,
          responsive: 100,
          cursorWidth: 2,
          plugins: [],
        };
        const pnt = this;

        setTimeout(() => {
          // Check if container still exists, search might have been reloaded
          if (!document.querySelector(params.container)) return;
          let item = pnt.foundFiles[fileObjectID];
          let surfer = new WaveSurfer(params);
          surfer.init();

          surfer.on("ready", async () => {
            item.surfer.status = "ready";
          });

          surfer.on("error", async () => {
            item.fileStatus = "error_read";
            item.surfer.status = "ready";
          });

          surfer.on("finish", async () => {
            item.surfer.play = 2;
          });

          surfer.on("seek", async () => {
            if (item.surfer.play === 2) {
              item.surfer.play = 0;
            }
          });

          item.surfer.surfer = surfer;
        }, 50);
      }
    },
    deleteBrowseFile(fileObjectID) {
      const file = this.foundFiles[fileObjectID];
      if (file) {
        delete this.foundFiles[fileObjectID];
        if (this.chosenItemID === fileObjectID) {
          this.chosenItemID = null;
          this.$emit("choose", this.chosenItemID);
        }
      }
    },
    addBrowseFile(item) {
      item.key = uuidv4();
      this.foundFiles[item.ID] = item;
      if (item.type_id === "library_noise") {
        this.initBrowseSurfer(item.ID);
      }
      const pnt = this;
      setTimeout(() => {
        if (item.type_id === "library_noise") {
          // pnt.setupBrowseWorkers(item.ID);
          pnt.loadAudioAsset(item.ID);
        }
      }, 200);
      this.updateCategoriesList(item);
    },
    Uint8Concat(a, b) {
      var c = new Uint8Array(a.length + b.length);
      c.set(a);
      c.set(b, a.length);
      return c;
    },
    uploadFiles() {
      if (this.selectedFileType.mime_type === "audio") {
        this.uploadingFiles = true;
        // wav --> ogg

        if (!this.encWorker) {
          this.encWorker = new Worker(encoderPath);

          this.uploadOggFiles.clear();
          this.uploadOggFilesInfo.clear();
          this.uploadOggData = {};
          this.encWorker.onmessage = ({ data }) => {
            if (data.message === "done") {
              if (this.uploadOggFiles.size > this.uploadOggData.num + 1) {
                this.uploadOggData.num++;
                this.uploadOggData.key = Array.from(this.uploadOggFiles)[
                  this.uploadOggData.num
                ][0];
                this.runUploadEncWorker(this.files[this.uploadOggData.key]);
              } else {
                this.uploadFilesData();
              }
            } else if (data.message === "page") {
              this.uploadOggFiles.set(
                this.uploadOggData.key,
                this.Uint8Concat(
                  this.uploadOggFiles.get(this.uploadOggData.key),
                  data.page,
                ),
              );
            }
          };
        }

        for (let key in this.files) {
          if (
            this.filesStatus.get(key) !== "error_read" &&
            !this.uploadFfmpegOggFiles.has(key)
          ) {
            this.uploadOggFiles.set(key, new Uint8Array(0));
            this.uploadOggFilesInfo.set(key, {});
          }
        }
        if (this.uploadOggFiles.size > 0) {
          this.uploadOggData = {
            num: 0,
            key: Array.from(this.uploadOggFiles)[0][0],
          };
          this.runUploadEncWorker(this.files[this.uploadOggData.key]);
        } else {
          // rows only with 'error_read' status
          this.uploadFilesData();
        }
      } else {
        // q-image and other
        this.uploadFilesData();
      }
    },
    uploadFilesData() {
      this.uploadingFiles = true;
      let cnt = 0;
      let cntErr = 0;
      let corruptedErr = 0;
      for (let key in this.files) {
        if (this.filesStatus.get(key) == "error_read") {
          cnt++;
          corruptedErr++;
          continue;
        }
        const formData = new FormData();
        formData.append("object_id", this.library.ID);
        formData.append("file_type", this.selectedFileType.id);
        if (this.selectedFileType.mime_type === "audio") {
          let fileUint8Array = null;
          if (this.uploadFfmpegOggFiles.has(key)) {
            fileUint8Array = this.uploadFfmpegOggFiles.get(key);
          } else {
            fileUint8Array = this.uploadOggFiles.get(key);
          }
          formData.append(
            "file",
            new File(
              [
                new Blob([fileUint8Array], {
                  type: "audio/ogg",
                }),
              ],
              this.fileInfo.get(key).name + ".ogg",
              { type: "audio/ogg" },
            ),
          );
        } else {
          formData.append("file", this.files[key]);
        }
        formData.append("name", this.fileInfo.get(key).name);
        let groups = [];
        this.uploadGroupTicked.forEach(function (item) {
          groups.push(item.FullName);
        });
        formData.append("groups", JSON.stringify(groups));
        if (this.uploadOggFilesInfo.get(key))
          formData.append("length", this.uploadOggFilesInfo.get(key).length);
        this.filesStatus.set(key, "loading");
        api
          .Call({
            url: "/api/v1/asset",
            data: formData,
            method: "post",
          })
          .then(
            (assetresp) => {
              cnt++;
              this.filesStatus.set(key, { success: true });
              if (cnt === this.filesCount) {
                this.uploadingFiles = false;
                if (cntErr || corruptedErr) {
                  Notify.create({
                    group: true,
                    timeout: 10000,
                    message: "Some files can't be upload",
                    color: "1",
                    textColor: "n",
                    classes: "round-both q-ml-lg q-mb-sm",
                    position: "bottom",
                  });
                }
                let succCnt = this.filesCount - cntErr - corruptedErr;
                if (succCnt) {
                  Notify.create({
                    group: true,
                    timeout: 10000,
                    message: "" + succCnt + " files was uploaded",
                    color: "1",
                    textColor: "p",
                    classes: "round-both q-ml-lg q-mb-sm",
                    position: "bottom",
                  });
                }
              }
            },
            (e) => {
              cnt++;
              cntErr++;
              this.filesStatus.set(key, { error: e.message });
              if (cnt === this.filesCount) {
                this.uploadingFiles = false;
                if (cntErr || corruptedErr) {
                  Notify.create({
                    group: true,
                    timeout: 10000,
                    message: "Some files can't be upload",
                    color: "1",
                    textColor: "n",
                    classes: "round-both q-ml-lg q-mb-sm",
                    position: "bottom",
                  });
                }
                let succCnt = this.filesCount - cntErr - corruptedErr;
                if (succCnt) {
                  Notify.create({
                    group: true,
                    timeout: 10000,
                    message: "" + succCnt + " files was uploaded",
                    color: "1",
                    textColor: "p",
                    classes: "round-both q-ml-lg q-mb-sm",
                    position: "bottom",
                  });
                }
              }
            },
          );
      }
      // all files corrupted (can't load form UI)
      if (corruptedErr == this.filesCount) {
        this.uploadingFiles = false;
        Notify.create({
          group: true,
          timeout: 10000,
          message: "The files corrupted and can't we upload",
          color: "1",
          textColor: "n",
          classes: "round-both q-ml-lg q-mb-sm",
          position: "bottom",
        });
      }
    },
    searchNextPage(index, done) {
      if (done) this.fnScrollDone = done;
      if (this.isSearchScrollEnd === true) {
        if (done) {
          done(true);
        }
        return;
      }
      this.isSearchLoading = true;
      if (!this.requestId) this.requestId = uuidv4();
      this.read(
        {
          Limit: 10,
          // Offset: Object.keys(this.foundFiles).length,
          Offset: 10 * (index - 1),
          LibraryID: this.library.ID,
          TypeID: this.filterFileType,
          Value: this.filterFile,
          CategoryFullName: this.group?.FullName,
        },
        "search_file_library",
        this.requestId,
      );
    },
    chooseItem(item) {
      if (!this.canchoose || item.fileStatus === "error_read") return;
      this.chosenItemID = item.ID;
      this.$emit("choose", this.chosenItemID);
    },
    showFullImageDialog(src) {
      this.fullImageSrc = src;
      this.showFullImage = true;
    },
    editItem(id, name) {
      this.editingItem.ID = id;
      this.editingItem.name = name; //name.substring(0, name.lastIndexOf("."));
      this.isEditingItem = false;
      this.focus("edititem_" + id);
    },
    editItemCancel() {
      this.editingItem.ID = null;
      this.editingItem.name = null;
    },
    focus(ref) {
      let obj = this.$refs[ref];
      if (obj) {
        setTimeout(() => {
          obj[0].focus();
        }, 50);
      }
    },
    // check if at least one of given categories fits currently selected (starts with)
    isInCurrentCategory(categories) {
      if ((this.group?.FullName || "") === "") return true;
      let result = false;
      if (Array.isArray(categories)) {
        categories.forEach((cat) => {
          if (cat.startsWith(this.group?.FullName)) result = true;
        });
      }
      return result;
    },
    getItemSrc(item) {
      if (item.error_read) return null;
      if (item.type_id === "library_img" && !item.error_read)
        return "/api/v1/asset/file/library_img/" + item.ID;
      if (item.type_id === "library_video" && !item.error_read)
        return "/api/v1/asset/file/library_video/" + item.ID;
    },
    startdrag(item) {
      this.$emit("startdrag", {
        FileID: item.file_id,
        FileObjectID: item.ID,
        Groups: item.categories_library,
      });
    },
  },
};
</script>

<style scoped></style>
